@import "../../assets/scss/core/variables";

.slick-slider {
    height: 100%;
}

.slick-list {
    width: 100vw;
    height: 100% !important;
}

.slick-track {
    height: 100%;
}

.slick-slide {
    border: none !important;
    & > div {
        height: 100%;
    }
}