@import 'src/assets/scss/core/variables';

.block {
  font-family: $font-stack-secondary;
  text-transform: lowercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 80px;
  font-size: 3.12vw;
  line-height: 120%;
  font-weight: 400;
  width: 52.31vw;
  @media (max-width: $first-breakpoint) {
    text-align: end;
    margin-bottom: 40px;
    padding: 0;
    width: 100%;
  }
  @media (max-width: $second-breakpoint) {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 25px;
  }
  &_active {
    .block {
      &__title {
        opacity: 0;
        transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
        animation: TextAnimation 0.7s ease forwards;
      }
      &__info {
        opacity: 0;
        transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
        animation: TextAnimation 0.5s 0.5s ease-in forwards;
      }
      &__extra {
        opacity: 0;
        transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
        animation: TextAnimation 0.5s 1s ease-in forwards;
      }
    }
  }
  &__title {
    margin-bottom: 64px;
    font-size: 3.12vw;
    line-height: 120%;
    font-weight: 400;
    letter-spacing: -0.01em;

    @media (max-width: 1279px) {
      text-align: end;
      margin-bottom: 24px;
      font-size: 8.06vw;
    }
  }
  &__info {
    font-family: $font-stack-primary;
    font-size: 1.56vw;
    line-height: 110%;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: auto;

    @media (max-width: 1279px) {
      width: 80%;
      margin-right: auto;
      margin-bottom: 200px;
      text-align: left;
      font-size: 4.03vw;
    }
    @media (max-width: $second-breakpoint) {
      font-size: 26px;
      line-height: 29px;
      width: 100%;
      margin-right: 0;
      margin-bottom: 130px;
    }
  }
  &__extra {
    max-width: 26vw;
    display: flex;
    align-self: flex-end;
    text-align: right;

    @media (max-width: $first-breakpoint) {
      width: 100%;
      max-width: 90%;
      margin-left: auto;
      text-align: right;
      justify-content: flex-end;
      font-size: 8.06vw;
      line-height: 120%;
    }

    @media(max-width:743px) {
      max-width: 100%;
    }
  }
}

@keyframes TextAnimation {
  from {
    opacity: 0;
    transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
