@import "../../../../assets/scss/core/variables";

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 2.12vh 0 4.25vh;
	transition: opacity 0.3s ease-in;

	@media (max-width: 1279px) and (min-width: 744px) {
		position: initial;
		padding: 0.617vh 0 1.677vh;
		height: 123px;
		margin-top: -123px;
	}

	@media (max-width: 743px) {
		position: relative;
		// top: -9.16vh;
		top: calc(var(--app-height) * 9.16 / 100 * -1);
		// padding: 16px 0 24px;
		padding: calc(var(--app-height) * 2.22 / 100) 0 calc(var(--app-height) * 3.33 / 100);
	}

	&__wrapper {
		width: calc(100% - 50px);
		margin: 0 auto;
		@media (max-width: $third-breakpoint) {
			width: calc(100% - 20px);
		}
	}
	&__list {
		display: flex;
		justify-content: space-between;
		font-size: 3.12vw;
		line-height: 120%;

		@media (max-width: 1279px) {
			font-size: 8.03vw;
		}

		@media (max-width: 743px) {
			// font-size: 3.05vh;
			font-size: calc(var(--app-height) * 3.05 / 100);
		}

		&::after {
			content: "";
			position: absolute;
			height: 1px;
			width: calc(100% - 50px);
			transform: translateX(-50%);
			top: 0;
			left: 50%;
			background-color: $color-text-light;
			@media (max-width: $third-breakpoint) {
				width: calc(100% - 10px);
			}
		}
		&_item {
			position: relative;
			transition: opacity 0.2s ease-in;
			&::after {
				content: "";
				position: absolute;
				height: 2px;
				width: 0;
				bottom: -2px;
				left: 0;
				background-color: $color-text-light;
				transition: width 0.3s ease;
			}
			&:hover {
				&::after {
					width: 100%;
				}
			}

			@media (max-width: 1279px) {
				&:after {
					width: 100%;
					height: 4px;
				}
			}

			@media (max-width: 1279px) {
				&:after {
					height: 2px;
				}
			}
		}
	}
}
