.dot {
    position: absolute;
    // width: 1.04vw;
    // height: 1.04vw;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%) scale(0);
    z-index: 20;
    transition: transform .5s ease-in-out, opacity .3s, width .15s ease-in-out, height .15s ease-in-out;
    pointer-events: none;

    @media(max-width: 1279px) {
      display: none;
    }

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      path {
        transition: 0.3s all ease;
      }
    }

    &_hover {
      width: 5.63vw;
      height: 5.63vw;
    }

    &_show {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }

    &_hide {
      opacity: 0;
      visibility: hidden;
      transform: scale(0);
    }
}

.svgContent {
  opacity: 0;

  &_hover {
    opacity: 1;
  }
}

.arrowBatya {
  fill: #000;
}