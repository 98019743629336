@import 'src/assets/scss/core/variables';

.about {
  display: flex;
  min-height: 100%;
  @media (max-width: $first-breakpoint) {
    flex-direction: column;
    min-height: auto;
    width: 100%;
  }
  &__title {
    font-size: 7.87vh;
    line-height: 120%;
    padding: 0px 34px 0px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background: $color-bg-light;
      transform: translateY(-100%);
      transition: transform 0.7s ease-in;
      @media (max-width: $first-breakpoint) {
        top: 100%;
        height: 1px;
        width: 100%;
        transform: translateX(100%);
      }
    }
    &_active {
      &::after {
        transform: translateY(0%);
        @media (max-width: $first-breakpoint) {
          transform: translateX(0%);
        }
      }
    }

    @media (max-width: $first-breakpoint) {
      font-size: 9.94vw;
      line-height: 120%;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 32px 0 24px;
    }
  }
  &__section {
    display: flex;
    padding: 0 80px;

    @media (max-width: $first-breakpoint) {
      flex-direction: column;
      padding: 28px 0 50px;
    }
  }
  &__content {
    display: flex;
  }
  &__link {
    font-size: 1.56vw;
    line-height: 110%;
    font-weight: 500;
    opacity: 0.4;
    color: $color-text-light;
    border-bottom: 2px solid currentColor;
    width: fit-content;
    margin: 10px auto 65px;

    @media(max-width:1279px) {
      font-size: 4.03vw;
    }

    @media (max-width: $second-breakpoint) {
      margin-bottom: 60px;
    }

    @media (max-width: $third-breakpoint) {
      font-size: 8.32vw;
    }
  }
}

.content {
  @media (max-width: $first-breakpoint) {
    flex-direction: column;
  }
  &__container {
    position: relative;
    &:first-child {
      margin-right: 80px;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transition: height 1s ease-in;
        background-color: $color-bg-dark;
      }
      @media (max-width: $first-breakpoint) {
        &::after {
          width: 100%;
          transition: width 1s ease-in;
          background-color: $color-bg-dark;
        }
      }
      @media (max-width: $first-breakpoint) {
        margin: 0;
        margin-bottom: 15px;
      }

      .content__container_image {
        min-width: 1120px;
        aspect-ratio: 11/8;
        height: auto;
        object-fit: cover;
        @media (max-height: 960px) and (min-width: $first-breakpoint) {
          min-width: 900px;
        }
        @media (max-height: 900px) and (min-width: $first-breakpoint) {
          min-width: 800px;
        }
        @media (max-height: 800px) and (min-width: $first-breakpoint) {
          min-width: 700px;
        }
        @media (max-width: $first-breakpoint) {
          min-width: 100%;
        }
      }
    }
    &:last-child {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transition: height 1s ease-in;
        background-color: $color-bg-dark;
      }
      @media (max-width: $first-breakpoint) {
        &::after {
          width: 100%;
          transition: width 1s ease-in;
          background-color: $color-bg-dark;
        }
      }
      .content__container_image {
        min-width: 560px;
        aspect-ratio: 2/3;
        height: auto;
        object-fit: cover;
        @media (max-height: 960px) and (min-width: $first-breakpoint) {
          min-width: 450px;
        }
        @media (max-height: 900px) and (min-width: $first-breakpoint) {
          min-width: 400px;
        }
        @media (max-height: 800px) and (min-width: $first-breakpoint) {
          min-width: 350px;
        }
        @media (max-width: $first-breakpoint) {
          min-width: 100%;
        }
      }
    }
    &_active {
      &:first-child {
        &::after {
          height: 0px;
        }
        @media (max-width: $first-breakpoint) {
          &::after {
            width: 0px;
            height: 101%;
          }
        }
      }
      &:last-child {
        &::after {
          height: 0px;
        }
        @media (max-width: $first-breakpoint) {
          &::after {
            width: 0px;
            height: 101%;
          }
        }
      }
    }
  }
}

.section {
  display: flex;
  &__info {
    margin-right: 80px;
    display: flex;
    flex-direction: column;
    width: 35.41vw;
    flex-shrink: 0;
    @media (max-width: $first-breakpoint) {
      flex-direction: column;
      margin: 0;
      width: 100%;
      min-width: fit-content;
      margin-bottom: 64px;
    }

    @media(max-width:743px) {
      margin-bottom: 32px;
    }

    &_active {
      .section__info {
        &_main {
          opacity: 1;
          transform: translate(0, 0%) skew(0, 0) scale(1, 1);
        }
        &_extra {
          opacity: 1;
          transform: translate(0, 0%) skew(0, 0) scale(1, 1);
        }
      }
    }
    &_main {
      width: 100%;
      font-family: $font-stack-secondary;
      font-weight: 400;
      text-transform: lowercase;
      font-size: 3.12vw;
      line-height: 120%;
      margin-bottom: 35px;
      transform-origin: 0% 50%;
      transform: translate(0, 25%) skew(-15deg, 0) scale(1, 1.2);
      opacity: 0;
      transition: transform 1s ease-in, opacity 0.5s ease-in 0.5s;
      @media (max-width: $first-breakpoint) {
        margin-bottom: 64px;
        text-align: end;
        width: 90%;
        margin-left: auto;
        font-size: 8.06vw;
      }
      @media (max-width: $second-breakpoint) {
        margin-bottom: 30px;
        font-size: 30px;
        line-height: 36px;
        text-align: end;
        width: 90%;
      }
    }
    &_extra {
      font-size: 1.56vw;
      line-height: 110%;
      transform-origin: 0% 50%;
      transform: translate(0, 25%) skew(-15deg, 0) scale(1, 1.2);
      opacity: 0;
      transition: transform 0.7s ease-in 0.5s, opacity 0.5s ease-in 0.8s;
      @media (max-width: $first-breakpoint) {
        width: 70%;
        margin-right: auto;
        font-size: 4.03vw;
      }
      @media (max-width: $second-breakpoint) {
        font-size: 26px;
        line-height: 28px;
        width: 90%;
      }
    }
  }
}

.modal {
  &_enter_active {
    animation: ModalApper 0.5s ease-in;
    & > div {
      opacity: 0;
      animation: ModalOpacity 0.7s ease-in 0.5s forwards;
    }
  }
  &_exit_active {
    animation: ModalDisapper 0.5s ease-in forwards;
  }
}

@keyframes ModalApper {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes ModalOpacity {
  to {
    opacity: 1;
  }
}

@keyframes ModalDisapper {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
