@import "../../assets/scss/core/variables";
.header {
	$parent: &;
	position: fixed;
	width: 100%;
	z-index: 11;
	transition: opacity 0.5s ease;

	&_vine {
		width: 50%;
		transition: width 0.1s 0.5s ease;
	}

	&_batya {
		right: 0;
		width: 50%;
		transition: width 0.1s 0.5s ease;
	}

	&__wrapper {
		padding: 10px 25px;
		position: relative;
		width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 11;

		@media (max-width: 743px) {
			padding: 10px 16px;
		}
	}
	&__logoWrapper {
		position: relative;
	}
	&__logo {
		display: flex;
		gap: 15px;
		cursor: pointer;
		transition: 0.5s opacity ease;

		svg {
			width: 64px;
			height: 64px;

			path {
				transition: 1.5s all ease-in;
			}

			@media (max-width: $third-breakpoint) {
				width: 48px;
				height: 48px;
			}
		}

		&_vine {
			.vineBg {
				fill: $color-bg-red;
			}
			.vineText {
				fill: #000000;
			}
		}
		&_batya {
			.vineBg {
				fill: $logo-gray;
			}
			.vineText {
				fill: $logo-gray;
			}

			&:hover {
				.vineBg {
					fill: #fff;
				}
				.vineText {
					fill: #fff;
				}
			}
		}

		&_hide {
			opacity: 0;
			visibility: hidden;
		}

		&_opacity {
			@media (max-width: 743px) {
				opacity: 0.3;
			}
		}

		&_invert {
			position: absolute;
			top: 0;
			right: -25px;
			padding-right: 25px;
			display: flex;
			justify-content: flex-end;
			width: 201px;
			clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
			z-index: 1;
			transition: clip-path 150ms linear;

			@media (max-width: $third-breakpoint) {
				display: none;
			}

			&#{$parent}__logoBatya {
				&:not(&_inactive) {
					svg {
						path {
							fill: $logo-gray;
						}
					}
				}

				&_inactive {
					svg {
						path {
							fill: #fff;
						}
					}
				}
			}
		}
	}
	&__logoBatya {
		transition: 0.5s opacity ease;
		svg {
			path {
				transition: 1.5s fill ease-in;
			}
		}
		&_inactive {
			svg {
				path {
					fill: $logo-gray;
				}
			}

			&:hover {
				svg {
					path {
						fill: #000000;
						transition: 0.5s all ease;
					}
				}
			}
		}

		&_opacity {
			opacity: 0.3;

			&:hover {
				opacity: 1;
			}
		}

		&_hide {
			opacity: 0;
			visibility: hidden;
		}
	}
	&__burger {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 75px;
		width: 68px;
		height: 68px;
		cursor: pointer;

		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		@media (max-width: 743px) {
			width: 58px;
			height: 48px;
			margin-left: 0;
		}
	}
}

.menu {
	pointer-events: all;

	&_enter_active {
		pointer-events: none;

		ul > li {
			opacity: 0;
			transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
			animation: TextAnimation 0.5s 1s ease forwards;

			& > a {
				@media (max-width: 1279px) {
					animation: TextAnimationXs 0.5s 1s ease forwards;
				}
			}

			& > a.link {
				@media (max-width: 1279px) {
					animation: TextAnimation 0.5s 1s ease forwards;
				}
			}
		}
		svg {
			opacity: 0;
			transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
			animation: TextAnimation 0.5s 1s ease forwards;
		}
		a {
			opacity: 0;
			transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
			animation: TextAnimation 0.5s 1s ease forwards;
		}
		& > div {
			& > footer {
				& > div {
					& > ul {
						&:after {
							width: 0;
							animation: LineAnimation 1s 1s ease forwards;
						}

						& > li {
							& > a {
								@media (max-width: 1279px) {
									animation: TextAnimation 0.5s 1s ease forwards;
								}
							}
						}
					}
				}
			}
		}
		& > div {
			& > div {
				& > section {
					&:nth-child(1) {
						animation: MenuAppear 0.5s ease-in;
					}
					&:nth-child(2) {
						opacity: 0;
						animation: MenuAppear 0.5s ease-in 0.3s, MenuOpacity 0.5s ease-in 0.2s forwards;
					}

					& > div {
						ul > li {
							& > a {
								@media (max-width: 1279px) {
									animation: TextAnimation 0.5s 1s ease forwards;
								}
							}
						}
					}
				}
			}
		}
	}
	&_exit_active {
		animation: MenuDisapper 0.5s ease-in forwards;

		& > footer {
			opacity: 0;
		}
		& > div {
			& > section {
				& > nav {
					opacity: 0;
				}
				& > div {
					opacity: 0;
				}
			}
		}
	}

	&_exitVine {
		transform: translateY(0);
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: calc(var(--app-height) + 10px);
			background-color: $color-bg-red;
			z-index: 2;
			transform: translateY(var(--app-height));
		}

		@media (max-width: 1279px) {
			&:after {
				height: calc(150vh + 10px);
			}
		}
	}

	&_exitVine_active {
		transform: translateY(calc(-100vh - 20px));
		transition: transform 0.7s 0.7s ease-in;
		&:after {
			transform: translateY(0);
			transition: 0.7s transform ease-in;
		}

		@media (max-width: 1279px) {
			transform: translateY(calc(-150vh - 20px));
		}
	}

	&_exitBatya {
		transform: translateY(0);
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: calc(var(--app-height) + 10px);
			background-color: #000;
			z-index: 2;
			transform: translateY(var(--app-height));
		}

		@media (max-width: 1279px) {
			&:after {
				height: calc(150vh + 10px);
			}
		}
	}

	&_exitBatya_active {
		transform: translateY(calc(-100vh - 20px));
		transition: transform 0.7s 0.7s ease-in;
		&:after {
			transform: translateY(0);
			transition: 0.7s transform ease-in;
		}

		@media (max-width: 1279px) {
			transform: translateY(calc(-150vh - 20px));
		}
	}
}

.burger {
	$p: &;
	transition: 0.5s opacity ease;

	&_hide {
		opacity: 0;
		visibility: hidden;
	}

	// svg {
	//   &:first-child {
	//     path {
	//       transition: 1.5s fill ease-in;
	//     }
	//   }
	// }

	&_vine {
		svg {
			&:first-child {
				path {
					fill: $color-bg-red;
				}
			}
		}

		#{$p} {
			&__line {
				&:before {
					background-color: $color-bg-red;
				}
				svg {
					path {
						fill: #fff;
					}
				}
			}
		}
	}

	&_open {
		svg {
			&:first-child {
				path {
					fill: #fff;
				}
			}
		}
	}
	&__lineWrap {
		position: absolute;
		width: 34px;
		height: 24px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 743px) {
			height: 18px;
		}

		&_open {
			#{$p} {
				&__line {
					opacity: 1;

					&_1 {
						&::before {
							animation: TransformLine 0.3s ease forwards;
						}
					}
					&_2 {
						&::before {
							animation: TransformLine 0.3s 0.1s ease forwards;
						}
					}
					&_3 {
						&::before {
							animation: TransformLine 0.3s 0.2s ease forwards;
						}
					}
				}
			}
		}
	}

	&__closeLineWrap {
		position: absolute;
		width: 34px;
		height: 24px;

		&_open {
			#{$p} {
				&__closeLine {
					opacity: 1;

					svg {
						path {
							fill: #000;
						}
					}

					&_1 {
						&::before {
							animation: TransformLine 0.3s 0.1s ease forwards;
						}
					}
					&_2 {
						&::before {
							animation: TransformLine 0.3s 0.2s ease forwards;
						}
					}
				}
			}
		}
	}

	&__closeLine {
		margin: 0;
		position: relative;
		display: block;
		overflow: hidden;
		opacity: 0;
		width: 40px;
		height: 7px;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #fff;
		}

		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
			vertical-align: top;

			path {
				fill: #000;
			}
		}

		&_1 {
			z-index: 1;
			transform: rotate(45deg) translate(8px, 7px);
		}
		&_2 {
			z-index: 2;
			transform: rotate(135deg) translate(2px, -3px);
		}

		@media (max-width: 743px) {
			width: 30px;
			height: 5px;

			&_1 {
				transform: rotate(45deg) translate(10px, 4px);
			}
			&_2 {
				transform: rotate(135deg) translate(1px, -6px);
			}
		}
	}

	&__line {
		position: relative;
		display: block;
		width: 34px;
		height: 6px;
		overflow: hidden;
		opacity: 0;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #fff;
		}

		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
			vertical-align: top;
		}

		@media (max-width: 743px) {
			width: 25px;
			height: 4px;
		}
	}
}

@keyframes TextAnimation {
	from {
		opacity: 0;
		transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
	}
	to {
		opacity: 1;
		transform: translate(0, 0);
	}
}

@keyframes TextAnimationXs {
	from {
		opacity: 0;
		transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
	}
	to {
		opacity: 0.5;
		transform: translate(0, 0);
	}
}

@keyframes LineAnimation {
	from {
		width: 0;
	}
	to {
		width: calc(100% - 50px);
	}
}

@keyframes TransformLine {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(100%);
	}
}

@keyframes MenuAppear {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(0%);
	}
}

@keyframes MenuOpacity {
	to {
		opacity: 1;
	}
}

@keyframes MenuDisapper {
	from {
		transform: translateY(0%);
	}
	to {
		transform: translateY(100%);
	}
}
