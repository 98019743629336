@import '../../assets/scss/core/variables';

.page {
  background: $color-bg-dark;
  color: $color-text-light;

  & > main {
    &::-webkit-scrollbar {
      width: 8px;
      height: 10px;
      display: none;

      @media(max-width: 1279px) {
        display: block;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: #fff;
      transition: 0.3s all ease;

      &:hover {
        background: $color-bg-red;
      }
    }
  }

  &__header {
    position: static;
  }
  &__footer {
    color: $color-text-light;
  }
  &__title {
    @media (max-width: $first-breakpoint) {
      font-size: 12.09vw;
      line-height: 120%;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 40px 0;
      align-self: flex-end;
    }
    @media (max-width: $second-breakpoint) {
      padding: 32px 0 24px;
    }
  }
  &__slider {
    & div {
      &::before {
        background-color: $color-bg-dark;
      }
    }
    &::before {
      background: $color-bg-light;
    }
    &::after {
      background: $color-bg-light;
    }
  }
  &__menu {
    h2 {
      &::after {
        background: $color-bg-light;
      }
      @media (max-width: $first-breakpoint) {
        padding: 32px 0 24px;
      }
    }
    &::after {
      background: $color-bg-light;
    }
    ul {
      position: relative;
      grid-template-rows: repeat(2, 1fr);

      &::after {
        background: $color-bg-light;
      }
      &::before {
        background: $color-bg-light;
      }
      li {
        background: $color-bg-dark;

        a {
          text-align: center;

          @media (max-width: $first-breakpoint) {
            width: min-content;
          }

          &::after {
            background-color: $color-bg-light;
          }
          &::before {
            background-image: url('../../assets/images/icon_pdf_light.svg');
            background-repeat: no-repeat;
          }
        }

        &:nth-child(1) {
          grid-area: 1 / 1 / 3 / 2;
          @media (max-width: $first-breakpoint) {
            grid-area: 1 / 1 / 2 / 3;

            a {
              width: auto;
            }
          }
        }
        &:nth-child(2) {
          grid-area: 1 / 2 / 2 / 3;
          @media (max-width: $first-breakpoint) {
            grid-area: 2 / 1 / 3 / 2;
          }
        }
        &:last-child {
          grid-area: 2 / 2 / 3 / 3;
          @media (max-width: $first-breakpoint) {
            grid-area: 2 / 2 / 3 / 3;
          }
        }
      }
    }
  }
  &__next {
    @media (max-width: $first-breakpoint) {
      flex-direction: row;
      & h4 {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}
