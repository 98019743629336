/*Fonts*/
$font-stack-primary: 'Roboto Flex';
$font-stack-secondary: 'firenight';
$font-stack-tertiary: 'Oswald';

/*Colors*/
/*&_text*/
$color-text-dark: #000000;
$color-text-light: #ffffff;
$color-text-red: #ea2434;
$color-text-warm: #b5b1a7;
$color-text-disabled: #d7d9d9;
/*&_bg*/
$color-bg-cold: #eff1f1;
$color-bg-warm: #f1ede3;
$color-bg-red: #ea2434;
$color-bg-dark: #000000;
$color-bg-light: #ffffff;
$color-bg-disabled: #d7d9d9;
$logo-gray: #A8A9A9;
/*&_buttons*/
$color-buttons-red: #ea2434;

/*BreakPoints*/
$first-breakpoint: 1279px;
$second-breakpoint: 743px;
$third-breakpoint: 500px;
