@import '../../../assets/scss/core/variables';

.products {
  display: flex;
  height: 100%;
  @media (max-width: $first-breakpoint) {
    flex-direction: column;
    height: auto;
  }
  &__block {
    display: flex;
    margin-right: 80px;
    flex-shrink: 0;

    &:last-of-type {
      margin-right: 0;
    }
    @media (max-width: $first-breakpoint) {
      flex-direction: column;
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
  &__title {
    padding: 0 35px 0 35px;
    font-size: 6.7vh;
    line-height: 120%;
    position: relative;
    margin-right: 80px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background: $color-bg-dark;
      transform: translateY(-100%);
      transition: transform 0.7s ease-in;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background: $color-bg-dark;
      transform: translateY(-100%);
      transition: transform 0.7s ease-in;
      @media (max-width: $first-breakpoint) {
        top: 100%;
        height: 1px;
        width: 100%;
        transform: translateX(100%);
      }
    }
    &_active {
      &::before {
        transform: translateY(0%);
        @media (max-width: $first-breakpoint) {
          transform: translateX(0%);
        }
      }
      &::after {
        transform: translateY(0%);
        @media (max-width: $first-breakpoint) {
          transform: translateX(0%);
        }
      }
    }
    @media (max-width: $first-breakpoint) {
      font-size: 10.2vw;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 32px 0 24px;
      margin-right: 0;
      margin-bottom: 32px;
      &::after {
        content: '';
        top: 100%;
        height: 1px;
        width: 100%;
      }
      &::before {
        content: '';
        top: 0;
        height: 1px;
        width: 100%;
      }
    }
    @media (max-width: 743px) {
      font-size: 10.55vw;
      line-height: 120%;
      padding: 20px 0 15px;
    }
  }
  &__cards {
    display: grid;
    place-items: center;

    @media(min-width: 1280px) {
      &_mod {
        & > div {
          &:nth-last-child(-n+3) {
            grid-row: 1/3;
            min-height: auto;
            max-height: 40.5vh;
            height: 100%;
  
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: -5px;
              width: 1px;
              height: calc(200% + 10px);
              transform: translateY(-50%);
              background-color: #000;
              z-index: 100;
            }
  
            &::after {
              content: "";
              position: absolute;
              top: 50%;
              right: -5px;
              width: 1px;
              height: calc(200% + 10px);
              transform: translateY(-50%);
              background-color: #000;
              z-index: 100;
            }
          }
  
          &:nth-last-child(-n+2) {
            grid-row: 1/2;
  
            &:before {
              right: -5px;
              left: auto;
              height: calc(100% + 10px);
              width: 1px;
            }
  
            &::after {
              content: "";
              position: absolute;
              top: auto;
              bottom: -5px;
              left: -5px;
              width: calc(100% + 10px);
              height: 1px;
              background-color: #000;
              z-index: 100;
            }
          }
  
          &:nth-last-child(-n+1) {
            grid-row: 2/3;
  
            &::after {
              display: none;
            }
          }
        }
      }
    }

    @media(max-width: 1599px) {
      &_mod {
        & > div {
          &:nth-last-child(-n+3) {
            max-height: 260px;
          }
        }
      }
    }

    @media(max-width:1279px) {
      & > div {
        &:nth-last-child(-n+3) {
          &:before {
            content: "";
            position: absolute;
            top: -5px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #000;
            z-index: 2;
          }
        }
      }
    }

    @media (max-width: $first-breakpoint) {
      grid-template-columns: 1fr;
    }
  }
  &__extra {
    // display: flex;
    padding: 0 10px;
    flex-wrap: wrap;
    display: grid;
    // grid-template-columns: repeat(2, 511px);
    // grid-template-rows: repeat(2, 300px);
    grid-auto-columns: 68.8vh;
    grid-template-rows: repeat(2, 40.5vh);
    gap: 10px;
    align-content: center;
    border-left: 1px solid $color-bg-dark;
    border-right: 1px solid $color-bg-dark;

    @media (max-width: 1599px) {
      grid-template-columns: repeat(2, 443px);
      grid-template-rows: repeat(2, 260px);
    }
    @media (max-width: 1439px) {
      grid-template-columns: repeat(2, 433px);
      grid-template-rows: repeat(2, 255px);
    }
    @media (max-width: 1279px) {
      grid-template-columns: repeat(2, 374px);
      grid-template-rows: repeat(2, 220px);
    }
    @media (max-width: 1024px) {
      padding: 0;
      grid-template-columns: 1fr;
      grid-template-rows: none;
      grid-auto-columns: 1fr;
      grid-auto-flow: dense;
      grid-auto-rows: 414px;
      max-width: 700px;
      width: 100%;
      align-self: center;
    }
    @media (max-width: 743px) {
      grid-auto-rows: 190px;
      max-width: 320px;
    }
    & > div {
      @media (max-width: $first-breakpoint) {
        border-bottom: 1px solid $color-bg-dark;
      }
      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
        // border-bottom: 1px solid $color-bg-dark;
        @media (max-width: $first-breakpoint) {
          grid-area: auto;
          border-bottom: none;
          // border-top: 1px solid $color-bg-dark;
        }
      }
      &:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
        @media (max-width: $first-breakpoint) {
          grid-area: auto;
        }
      }
      &:nth-child(3) {
        grid-area: 1 / 2 / 2 / 3;
        @media (max-width: $first-breakpoint) {
          grid-area: auto;
          border-left: none;
        }
        // border-left: 1px solid $color-bg-dark;
      }
    }
  }
}

.description {
  font-family: $font-stack-secondary;
  font-weight: 400;
  width: 37.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 35px;
  @media (max-width: $first-breakpoint) {
    margin: 0;
    width: 100%;
    margin-bottom: 100px;
  }
  &__info {
    font-size: 3.12vw;
    line-height: 120%;
    font-weight: 400;
    text-transform: none;
    transform-origin: 0% 50%;
    transform: translate(0, 25%) skew(-15deg, 0) scale(1, 1.2);
    opacity: 0;
    transition: transform 1s ease-in, opacity 0.5s ease-in 0.5s;
    &_active {
      opacity: 1;
      transform: translate(0, 0%) skew(0, 0) scale(1, 1);
    }

    @media (max-width: $first-breakpoint) {
      text-align: end;
      margin-bottom: 100px;
      font-size: 8.06vw;
    }
    @media (max-width: $second-breakpoint) {
      font-size: 8.3vw;
      line-height: 120%;
    }
  }
  &__container {
    margin: auto 0;
    opacity: 0;
    transition: 0.5s opacity 0.3s ease-in;

    &_active {
      opacity: 1;
    }
  }
}
