@import '../../../../assets/scss/core/variables';

.slider {
  height: 100%;
  z-index: 10;

  &__plug {
    width: 100%;
    height: var(--app-height);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
}

.sliderClose {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 22px;
  left: 32px;
  z-index: 20;
  width: 48px;
  height: 48px;
  transition: 0.3s opacity ease;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    vertical-align: top;
  }

  &_show {
    opacity: 1;
    visibility: visible;
  }

  @media(max-width: 1279px) {
    display: block;
  }

  @media(max-width: 743px) {
    top: 10px;
    left: 16px;
  }
}

.footerXs {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 15px;
  left: 0;
  z-index: 20;
  width: 80px;
  flex-direction: column;
  align-items: center;
  pointer-events: none;

  @media(max-width: 743px) {
    display: flex;
  }

  &_show {
    opacity: 1;
    visibility: visible;
    transition: 0.3s 0.3s all ease;
  }

  &_hide {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all ease;
  }

  &__logo {
    width: 24.6vh;
    transform: rotate(-90deg) translateX(130px);

    svg {
      width: 100%;
      height: auto;
      object-fit: contain;
      vertical-align: bottom;
    }
  }

  &__arrow {
    transform: rotate(-90deg);
    width: 24px;
    height: 24px;
    opacity: 0.2;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: top;
    }
  }
}

.logoBatya {
  display: none;

  @media(max-width: 743px) {
    display: block;
    position: absolute;
    top: calc(68px + 19px);
    right: 16px;
    z-index: 11;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s opacity ease;

    &_show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.footer {
  opacity: 0.6;
  transition: 0.3s all ease;
      
  @media (max-width: 1279px) {
    opacity: 1;
  }

  @media (max-width: 743px) {
    opacity: 0;
    visibility: hidden;
  }

  &_hide {
    opacity: 0 !important;
    visibility: hidden !important;
    transition: 0.5s all ease;
  }

  &_hover {
    opacity: 1;
    visibility: visible;
  }

  &__icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(calc(-100% - 2.4vh));
    width: 10.75vw;
    height: 10.75vw;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: top;
    }

    @media(max-width: 1279px) {
      display: block;
    }

    @media(max-width: 743px) {
      width: 20vw;
      height: 20vw;
    }
  }

  &__logo {
    margin: 0 0 4.9vh auto;
    width: 23.75vw;

    svg {
      width: 100%;
      height: auto;
    }

    &_batya {
      text-align: right;
    }

    @media(max-width: 1279px) {
      margin-bottom: 22.72vh;
      width: 30.23vw;
    }

    @media(max-width: 743px) {
      display: none;
    }
  }

  &__nav {
    margin-bottom: 2.13vh;

    @media(max-width: 1279px) {
      margin-bottom: 3.54vh;
    }

    @media(max-width: 743px) {
      margin-bottom: 5.3vh;
    }
  }

  &__socials {
    margin-top: 1.7vh;

    @media(max-width: 1279px) {
      margin-top: 1.6vh;
    }

    @media(max-width: 743px) {
      margin-top: 2.8vh;
    }
  }

  &__dividerBatya {
    position: relative;
    display: block;
    height: 1px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #fff;
      opacity: 0;
      transition: 0.3s all ease;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 25px;
      width: 100vw;
      height: 1px;
      background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 65.25%, rgba(255, 255, 255, 0) 100%);
      transition: 0.3s all ease;
    }

    &_hover {
      &:before {
        opacity: 1;
      }
      &:after {
        opacity: 0;
      }
    }

    @media(max-width: 1279px) {
      &:before {
        opacity: 1;
      }
      &:after {
        opacity: 0;
      }
      &_hover {
        &:before {
          opacity: 1;
        }
        &:after {
          opacity: 0;
        }
      }
    }

    @media(max-width: 743px) {
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }

      &_hover {
        &:before {
          opacity: 1;
        }
        &:after {
          opacity: 0;
        }
      }
    }
  }
}

.slideDescr {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--app-height);
  z-index: 20;
  pointer-events: none;
  display: none;

  @media(max-width: 1279px) {
    display: block;
  }

  &_show {
    opacity: 1;
    visibility: visible;
  }

  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    color: #fff;

    &_show {
      opacity: 1;
      visibility: visible;
    }
  }

  &__title {
    position: absolute;
    top: calc(88px + 2vh);
    left: 25px;
    font-family: $font-stack-secondary;
    font-weight: 400;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    letter-spacing: -0.01em;
    font-size: 3.53vh;
    line-height: 120%;

    @media (max-width:743px) {
      top: calc(68px + 2vh);
      left: 10px;
    }
  }

  &__text {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 0 32px 25px;
    width: 67vw;

    p {
      font-size: 1.24vh;
      font-variation-settings: normal;
      font-stretch: normal;
      text-transform: initial;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100vw;
      height: 146px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.433244) 28.77%, rgba(0, 0, 0, 0.751563) 60.7%, #000000 91.79%);
    }

    @media (max-width: 743px) {
      padding: 0 10px 3.33vh 10px;
      width: 100%;

      p {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.pagination {
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  pointer-events: none;
  color: #fff;
  font-family: $font-stack-secondary;
  font-weight: 400;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  font-size: 3.13vw;
  line-height: 120%;
  opacity: 0;
  transition: 0.3s opacity ease;

  &_show {
    opacity: 1;
  }

  @media(max-width: 1279px) {
    display: none;
  }
}

.static {
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    transition: 0.3s opacity ease;
    z-index: 10;
    visibility: hidden;

    &_show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.nav {
  $p: &;

  &_batya {
    #{$p} {
      &__list {
        justify-content: flex-end;
      }
    }
  }
  &__list {
    display: flex;
  }

  &__item {
    margin-right: 1.67vw;
    flex-shrink: 0;

    &:last-child {
      margin-right: 0;
    }

    @media(max-width: 1279px) {
      margin-right: 4.15vh;
    }

    @media(max-width: 743px) {
      margin-right: 5.98vh;
    }
  }

  &__link {
    font-size: 1.57vw;
    line-height: 110%;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    @media(max-width: 1279px) {
      font-size: 2.65vh;
    }

    @media(max-width: 743px) {
      font-size: 3.61vh;
      width: fit-content;
    }
  }
}

.socials {
  $p: &;
  display: flex;
  justify-content: flex-end;

  &_batya {
    #{$p} {
      &__list {
        justify-content: flex-end;
      }
    }
  }
  
  &__list {
    display: flex;
    align-items: center;
    margin-right: 2.3vw;

    @media(max-width: 1279px) {
      margin-right: 0.8vh;
    }

    @media (max-width: 743px) {
      margin-right: 1.67vh;
      display: flex;
    }
  }

  &__poster {
    span {
      font-size: 1.04vw;
      line-height: 150%;
    }

    @media(max-width: 1279px) {
      font-size: 1.77vh;
      line-height: 150%;

      span {
        font-size: 1.77vh;
      }
    }

    @media(max-width: 743px) {
      font-size: 2.5vh;

      span {
        font-size: 2.5vh;
      }
    }
  }

  &__item {
    margin-left: 1.67vw;

    @media(max-width: 1279px) {
      margin-left: 2.8vh;

      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        order: 1;
      }
      &:nth-child(3) {
        order: 3;
      }
    }

    @media (max-width: 743px) {
      margin-left: 1.67vh;
      display: flex;
    }
  }

  &__link {
    position: relative;
    font-size: 1.04vw;
    line-height: 110%;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 0;
      bottom: -2px;
      left: 0;
      background-color: $color-text-light;
      transition: width 0.3s ease;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }

    @media(max-width: 1279px) {
      font-size: 1.77vh;

      &::after {
        width: 100%;
      }
    }

    @media(max-width: 743px) {
      font-size: 2.5vh;

      &::after {
        height: 1px;
      }
    }
  }
}