@import "../../assets/scss/core/variables";

.menu {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	color: $color-text-light;
	z-index: 10;
	overflow: hidden overlay;

	&::-webkit-scrollbar {
		width: 8px;
		height: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: rgba(0, 0, 0, 0);
	}

	&::-webkit-scrollbar-thumb {
		background: #fff;
		transition: 0.3s all ease;

		&:hover {
			background: $color-bg-red;
		}
	}

	@media(max-width: 743px) {
		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__wrap {
		height: var(--app-height);
		position: relative;

		@media (max-width: 1279px) and (min-width: 744px) {
			height: auto;
			max-height: var(--app-height);
			overflow: auto;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	&__sections {
		height: 100%;

		flex-grow: 1;
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		@media (max-width: 1279px) and (min-width: 744px) {
			min-height: var(--app-height);
		}

		@media (max-width: 743px) {
			min-height: var(--app-height);
			height: auto;
		}
	}
}
