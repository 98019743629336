@import '../../../assets/scss/core/variables';

.slider {
  $p: &;
  padding: 0 15px;
  display: flex;
  position: relative;
  width: 80.78vw;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background: $color-bg-dark;
    transform: translateY(100%);
    transition: transform 0.7s ease-in;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: $color-bg-dark;
    transform: translateY(100%);
    transition: transform 0.7s ease-in;
  }

  @media (max-width: 1279px) {
    padding: 0;
    min-height: 40.42vh;
    margin-left: -16px;
    width: 100vw;

    &::after {
      content: none;
    }
    &::before {
      content: none;
    }
  }

  @media(max-width: 743px) {
    min-height: 61.7vw;
  }

  &_active {
    &::before {
      transform: translateY(0%);
    }
    &::after {
      transform: translateY(0%);
    }
    .slider {
      &__container {
        &::before {
          width: 0%;
        }
      }
      &__pagination {
        opacity: 1;
      }
      &__button {
        & svg {
          opacity: 1;
        }
      }
    }
    &_one {
      .slider__slide_1 {
        &::before {
          width: 0%;
        }
      }
    }
  }

  &__container {
    position: relative;
    // transition: transform 1s ease-in 0.7s;
    // transform: translateX(-150%);
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0px;
      height: 100%;
      width: 100%;
      transform-origin: left;
      transition: width 1s ease-in 0.7s;
      background: $color-bg-cold;
      z-index: 10;
    }
  }
  &__slide {
    display: flex;
    width: 1900px;
    height: auto;
    &_1 {
      // transform: translateX(-150%);
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 1px;
        height: 100%;
        width: 100%;
        transform-origin: left;
        transition: width 1s ease-in 0.7s;
        background: $color-bg-cold;
        @media (max-width: $first-breakpoint) {
          left: 0px;
        }
      }
    }
    &_img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 16/9;
    }
  }

  &__leftSide,
  &__rightSide {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 3;

    @media (max-width: 1279px) {
      top: 50%;
      transform: translateY(-50%);
      width: 6.18vw;
      height: 6.18vw;

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
        vertical-align: top;
      }
    }

    @media(max-width:743px) {
      width: 13.33vw;
      height: 13.33vw;
    }
  }

  &__leftSide {
    left: 0;

    @media(max-width: 1279px) {
      left: 18px;
    }
  }

  &__rightSide {
    right: 0;

    @media(max-width: 1279px) {
      right: 18px;
    }
  }

  &__button {
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: transform .5s ease-in-out, opacity .3s;
    width: 5.624vw;
    height: 5.624vw;

    &_hide {
      transform: translate(-50%, -50%) scale(0);
    }

    & svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: top;
      opacity: 0;
    }

    @media (max-width: 1279px) {
      display: none;
    }
  }

  &__buttonBg {
    fill: #000;
  }

  &__buttonEl {
    transition: 0.5s all ease;
  }

  &__pagination {
    transition: opacity 0.5s ease-in 1.2s;
    opacity: 0;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    z-index: 3;
    font-weight: 400;
    font-size: 60px;
    line-height: 120%;
    font-family: $font-stack-secondary;
    letter-spacing: -0.01em;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    color: #fff;
    z-index: 5;

    @media(max-width: 1279px) {
      bottom: 16px;
      font-size: 4.03vw;
    }

    @media(max-width: 743px) {
      font-size: 8.33vw;
    }
  }
}
