/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 500;
  font-stretch: 40.2%;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/new/RobotoFlex_cyrillic-ext.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 500;
  font-stretch: 40.2%;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/new/RobotoFlex_cyrillic.woff2") format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 500;
  font-stretch: 40.2%;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/new/RobotoFlex_greek.woff2") format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 500;
  font-stretch: 40.2%;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/new/RobotoFlex_vietnamese.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 500;
  font-stretch: 40.2%;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/new/RobotoFlex_latin-ext.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 500;
  font-stretch: 40.2%;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/new/RobotoFlex_latin.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/new/RobotoFlex-Regular.ttf");
}
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wdth,wght,GRAD@8..144,40.2,600,-200&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300&display=swap');

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 600;
  font-stretch: 40.2%;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/SemiBold/RobotoFlex_SemiBold-cyrllic-ext.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 600;
  font-stretch: 40.2%;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/SemiBold/RobotoFlex_SemiBold-cyrllic.woff2") format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 600;
  font-stretch: 40.2%;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/SemiBold/RobotoFlex_SemiBold-greek.woff2") format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 600;
  font-stretch: 40.2%;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/SemiBold/RobotoFlex_SemiBold-vietnamese.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 600;
  font-stretch: 40.2%;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/SemiBold/RobotoFlex_SemiBold-latin-ext.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 600;
  font-stretch: 40.2%;
  font-display: swap;
  src: url("../../fonts/RobotoFlex/SemiBold/RobotoFlex_SemiBold-latin.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Oswald';
  src: url('../../fonts/Oswald/Oswald.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'firenight';
  src: url('../../fonts/Firenight/firenight-regular-webfont.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
}
