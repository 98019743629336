@import "../../../../assets/scss/core/variables";

.video {
	padding: 1.354vw 3.9vw 1.354vw 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #fff;
	cursor: pointer;

	@media (max-width: 1279px) {
		padding: 1.354vw 5.9vw 1.354vw 25px;
	}

	@media (max-width: 743px) {
		padding: 1.354vw 8.9vw 1.354vw 10px;
		//width: 28.6vh;
	}

	&_batya {
		padding: 1.354vw 25px 1.354vw 3.9vw;
		color: #000;
		align-items: flex-end;

		@media (max-width: 1279px) {
			//padding: 0.53vh 25px 0.53vh 3.9vw;
		}

		@media (max-width: 743px) {
			padding: 1.354vw 10px 1.354vw 3.9vw;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		min-height: 6.67vw;
		height: 100%;
		max-height: fit-content;

		svg {
			width: 100%;
			height: 100%;
			display: flex;

			path {
				flex-grow: 1;
			}
		}
	}

	&__head {
		margin-bottom: 0.37vw;
		display: flex;
		align-items: center;
		pointer-events: none;

		@media (max-width: 1279px) {
			margin-bottom: 0;
		}
	}

	&__icon {
		width: 0.73vw;
		height: 0.68vw;

		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
			vertical-align: top;
		}

		@media (max-width: 1279px) {
			width: 0.97vh;
			height: 0.97vh;
		}

		@media (max-width: 743px) {
			width: 1.53vh;
			height: 1.53vh;
		}
	}

	&__text {
		margin-left: 12px;
		font-size: 1.04vw;
		line-height: 100%;
		text-transform: uppercase;

		@media (max-width: 1279px) {
			margin-left: 8px;
			font-size: 1.6vh;
			text-decoration: underline;
			text-underline-offset: 2px;
		}

		@media (max-width: 743px) {
			font-size: 2.5vh;
		}
	}

	&__title {
		font-family: $font-stack-secondary;
		font-size: 2.08vw;
		line-height: 120%;
		font-weight: 400;
		pointer-events: none;

		@media (max-width: 1279px) {
			font-size: 2.3vh;
			letter-spacing: -0.01em;
		}

		@media (max-width: 743px) {
			font-size: 3.5vh;
		}
	}

	&__fullscreen {
		z-index: 50;
		cursor: auto;
		background-color: #000;
	}

	&__area {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(100% - 70px);
		z-index: 2;
		cursor: pointer;
	}

	&__close {
		position: absolute;
		right: 48px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 3;
		cursor: pointer;
		width: 3.33vw;
		height: 3.33vw;

		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
			vertical-align: top;
		}

		@media (max-width: 1279px) {
			top: 28px;
			right: 32px;
			transform: translateY(0);
			width: 3.9vh;
			height: 3.9vh;
		}

		@media (max-width: 1279px) {
			top: 16px;
			right: 16px;
			width: 48px;
			height: 48px;
		}
	}

	&__controlls {
		position: absolute;
		bottom: 50px;
		left: 0;
		width: 100%;
		z-index: 4;

		@media (max-width: 1279px) {
			bottom: 105px;
		}

		@media (max-width: 743px) {
			bottom: 63px;
		}
	}

	&__progress {
		display: block;
		width: calc(100% - 100px);
		height: 2px;
		overflow: hidden;
		-webkit-appearance: none;
		outline: none !important;
		cursor: pointer;
		background-image: linear-gradient(#fff0f0, #fff0f0);
		background-size: 0% 100%;
		background-repeat: no-repeat;
		transition: all 0.3s ease;

		&::-webkit-slider-runnable-track {
			height: 2px;
			-webkit-appearance: none;
			transition: height 0.2s ease;
		}

		&::-moz-range-track {
			height: 2px;
		}

		&::-ms-track {
			height: 2px;
		}

		&::-moz-focus-outer {
			border: 0;
		}

		&::-webkit-slider-thumb {
			width: 0;
			height: 0;
			transform: translate(-100%, 50%);
			-webkit-appearance: none;
			background: #fff;
			border: none;
			border-radius: 50%;
			box-shadow: none !important;
			outline: none !important;
			box-shadow: none;
		}

		&::-moz-range-thumb {
			width: 0;
			height: 0;
			-webkit-appearance: none;
			background: #fff;
			border: none;
			border-radius: 50%;
			box-shadow: none !important;
			outline: none !important;
			box-shadow: none;
		}
	}

	&__timeWrap {
		position: absolute;
		left: 0;
		bottom: 54px;
		width: calc(100% - 100px);

		@media (max-width: 1279px) {
			bottom: 109px;
		}

		@media (max-width: 743px) {
			bottom: 65px;
		}
	}

	&__time {
		position: absolute;
		left: 0;
		bottom: 0;
		transform: translate(24px, 50%);
		font-family: $font-stack-secondary;
		font-weight: 400;
		font-size: 40px;
		line-height: 120%;
		display: flex;
		transition: all 0.3s ease;
		color: #fff;

		@media (max-width: 1279px) {
			bottom: 109px;
		}

		@media (max-width: 743px) {
			transform: translate(10px, 50%);
			bottom: 0;
			font-size: 26px;
		}
	}
}
