@import '../../assets/scss/core/variables';

.card {
  $p: &;
  position: relative;
  font-size: 60px;
  line-height: 72px;
  font-weight: 500;
  position: relative;
  display: flex;
  background-color: $color-bg-light;
  min-width: 100%;
  min-height: 100%;
  cursor: pointer;
  // aspect-ratio: 2/1;
  &_active {
    .card__info {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  // &:after {
  //   content: '';
  //   position: absolute;
  //   top: -1px;
  //   left: -1px;
  //   width: calc(100% - 8px);
  //   height: calc(100% - 8px);
  //   border: 8px solid $color-bg-warm;
  //   z-index: 2;
  // }

  &:hover {
    #{$p} {
      &__info {
        color: $color-text-light;
      }
      &__stroke {
        stroke-dasharray: 4200, 4200;
        stroke-dashoffset: -4200;
      }
    }
  }
  &__info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -25%);
    text-decoration: underline;
    text-underline-offset: 0.85vh;

    transition: all 0.5s ease;
    text-align: center;
    opacity: 0;
    transition: color 0.5s 0.25s ease-in, transform 1s ease,
      opacity 0.5s ease 0.25s;
    z-index: 5;
    width: calc(100% - 103px);
    font-size: 3.125vw;
    line-height: 120%;

    @media(max-width: 1279px) {
      font-size: 8.06vw;
    }

    @media (max-width: 743px) {
      font-size: 22px;
      line-height: 28px;
      width: 80%;
      text-decoration: underline 3px;
    }
  }
  &__stroke {
    stroke-dasharray: 4200, 4200;
    stroke-dashoffset: 0;
    transition: 0.5s all ease-in;
  }
  &__container {
    display: flex;
    &_image {
      aspect-ratio: 7/4;
      object-fit: cover;
    }
  }
  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      position: relative;
      width: 99%;
      height: 99%;
      object-fit: cover;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      border: 8px solid $color-bg-warm;
      z-index: 2;
    }
  }
  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__svg {
    background: $color-bg-warm;
  }
}

.modal {
  &_enter_active {
    animation: ModalApper 0.5s ease-in forwards;
    & > div {
      opacity: 0;
      animation: ModalOpacity 0.7s ease-in 0.5s forwards;
    }
    & > button {
      opacity: 0;
      animation: ModalOpacity 0.7s ease-in 0.5s forwards;
    }
  }
  &_exit_active {
    animation: ModalDisapper 0.5s ease-in forwards;
  }

  @media(max-width: 1279px) {
    &_enter_active {
      opacity: 0;
      animation-delay: 0.5s;

      & > div {
        animation-delay: 1s;
      }
      & > button {
        animation-delay: 1s;
      }
    }
  }
}

@keyframes ModalApper {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  1% {
    opacity: 1;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes ModalOpacity {
  to {
    opacity: 1;
  }
}

@keyframes ModalDisapper {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
