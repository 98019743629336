@import '../../assets/scss/core/variables';

.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  padding-top: 88px;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  height: var(--app-height);

  @media (max-width: $third-breakpoint) {
    padding-top: 68px;
  }

  &_light {
    background: $color-bg-warm;
  }
}
