@import 'src/assets/scss/core/variables';
@import 'src/assets/scss/core/extends';

.form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  &__title {
    @extend %title-block;
    height: 110px;
    overflow-y: hidden;
    & > span {
      display: block;
      transform: translateY(200%);
      animation: TitleAppear 0.7s ease-in 0.7s forwards;
    }
    @media (max-width: $first-breakpoint) {
      margin-bottom: 10px;
    }
    @media (max-width: 768px) {
      font-size: 100px;
      line-height: 98px;
    }
    @media (max-width: 678px) {
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 5px;
      height: 80px;
    }
  }
  @keyframes TitleAppear {
    to {
      transform: translateY(0%);
    }
  }
  &__point {
    opacity: 0.3;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;
    @media (max-width: 1279px) {
      justify-content: flex-start;
    }
    @media (max-width: 678px) {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 40px;
    }
  }
  &__success {
    position: absolute;
    font-family: $font-stack-secondary;
    font-weight: 400;
    font-size: calc(70px + 5vw);
    line-height: 92px;
    text-transform: none;
    width: 100vw;
    height: var(--app-height);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: currentColor;
    color: currentColor;
    animation: submitSuccess 0.5s ease-in forwards;
    transform: translateX(-2%);
    text-align: center;
    @media (max-width: 500px) {
      transform: translateX(-5%);
      font-size: calc(30px + 5vw);
      line-height: 45px;
    }
    &_red {
      color: $color-text-light;
      background-color: $color-bg-red;
    }
    &_black {
      color: $color-text-light;
      background-color: $color-bg-dark;
    }
    &_cold {
      color: $color-text-dark;
      background-color: $color-bg-cold;
    }
  }
  &__block {
    flex: 1 1 auto;
    margin-bottom: 50px;
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    column-gap: calc(200px - 5vw);
    @media (max-width: 1500px) {
      column-gap: calc(100px - 5vw);
    }
    @media (max-width: 1230px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
      row-gap: calc(150px - 5vw);
    }
    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, auto);
      row-gap: 50px;
    }
    &_inputs {
      grid-area: 1 / 1 / 2 / 2;
      display: flex;
      flex-direction: column;
      @media (max-width: 1230px) {
        grid-area: 1 / 1 / 2 / 2;
      }
      @media (max-width: 800px) {
        grid-area: 1 / 1 / 2 / 2;
      }
    }
    &_name {
      @media (max-width: 800px) {
        margin-bottom: 25px;
      }
      & input {
        @media (max-width: 678px) {
          font-size: 26px;
          line-height: 29px;
          &::placeholder {
            font-size: 26px;
            line-height: 29px;
          }
        }
      }
    }
    &_number {
      margin-top: auto;
      & input {
        @media (max-width: 678px) {
          font-size: 26px;
          line-height: 29px;
          &::placeholder {
            font-size: 26px;
            line-height: 29px;
          }
        }
      }
    }
    &_socials {
      grid-area: 1 / 2 / 2 / 3;
      @media (max-width: 1230px) {
        grid-area: 1 / 2 / 2 / 3;
      }
      @media (max-width: 800px) {
        grid-area: 2 / 1 / 3 / 2;
      }
      & label {
        & div {
          text-align: start;
        }
      }
    }
    &_resume {
      margin-top: auto;
      grid-area: 1 / 3 / 2 / 4;
      @media (max-width: 1230px) {
        grid-area: 2 / 1 / 3 / 3;
      }
      @media (max-width: 800px) {
        grid-area: 3 / 1 / 4 / 2;
      }
      & input {
        @media (max-width: 678px) {
          font-size: 26px;
          line-height: 29px;
          &::placeholder {
            font-size: 26px;
            line-height: 29px;
          }
        }
      }
    }
    &_text {
      grid-area: 2 / 1 / 3 / 3;
      margin-top: 150px;
      height: auto;
      @media (max-width: 2000px) {
        margin-top: 100px;
      }
      @media (max-width: 1230px) {
        grid-area: 3 / 1 / 4 / 3;
        margin-top: 0px;
      }
      @media (max-width: 800px) {
        grid-area: 4 / 1 / 5 / 2;
      }
      & textarea {
        @media (max-width: 678px) {
          font-size: 26px;
          line-height: 29px;
          &::placeholder {
            font-size: 26px;
            line-height: 29px;
          }
        }
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 45px;
  @media (max-width: 1300px) {
    padding: 10px 0 25px;
  }
  @media (max-width: 678px) {
    flex-direction: column;
  }
  &__text {
    align-self: flex-end;
    @media (max-width: 1300px) {
      max-width: 890px;
      margin-right: 10px;
    }
    @media (max-width: 678px) {
      max-width: 100%;
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 18px;
    }
    &_link {
      border-bottom: 2px solid currentColor;
      padding-bottom: 0px;
      line-height: 25px;

      // &::after {
      //   content: '';
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   height: 1px;
      //   width: 0%;
      //   transition: width 0.3s ease;
      //   background: currentColor;
      // }
  
      // &:hover {
      //   &:after {
      //     width: 100%;
      //   }
      // }
    }
  }
  & button[type='submit'] {
    color: $color-text-red;
    @media (max-width: 768px) {
      font-size: 54px;
      line-height: 56px;
    }
    @media (max-width: 678px) {
      width: fit-content;
      font-size: 24px;
      line-height: 26px;
      align-self: center;
      &::after {
        height: 3px;
      }
    }
  }
}

@keyframes submitSuccess {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
