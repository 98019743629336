@import "../../assets/scss/core/variables";

.footer {
  font-size: 30px;
  line-height: 33px;
  padding: 25px 0 35px;
  flex: 0 0 auto;
  @media (max-height: 1050px) and (min-width: $first-breakpoint) {
    padding: 15px 0 25px;
  }
  &__wrapper {
    margin: 0 auto;
    width: calc(100% - 50px);
    display: flex;
    justify-content: space-between;
  }

  &__info {
    font-size: 1.56vw;
    line-height: 110%;
    color: #B5B1A7;
  }

  &__links {
    margin-left: auto;
    color: currentColor;
    display: flex;
    // gap: 65px;
    font-size: 30px;
    line-height: 33px;
    font-weight: 500;
  }
  &__link {
    display: block;
    color: currentColor;
    position: relative;
    cursor: pointer;
    font-weight: 600;
    margin-right: 65px;

    &:last-child {
      margin-right: 0;
    }

    &::after {
      content: "";
      position: absolute;
      height: 3px;
      width: 0;
      bottom: -1px;
      left: 0;
      background-color: currentColor;
      transition: 0.3s width ease;
    }
    &_red {
      color: $color-text-red;
    }
    &_black {
      opacity: 0.4;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
}

.modal {
  &_enter_active {
    animation: ModalApper 0.5s ease-in;
    & > div {
      opacity: 0;
      animation: ModalOpacity 0.7s ease-in 0.5s forwards;
    }
  }
  &_exit_active {
    animation: ModalDisapper 0.5s ease-in forwards;
  }
}

@keyframes ModalApper {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes ModalOpacity {
  to {
    opacity: 1;
  }
}

@keyframes ModalDisapper {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
