@import '../../assets/scss/core/variables';

.customLink {
    $p: &;
    position: relative;

    &:hover {
        #{$p}__wrap {
            // width: 100%;
            width: calc(100% + 1.97vw);
            opacity: 1;
            transition: 0.2s width ease;

            @media(max-width: 743px) {
                width: calc(100% + 2.3vh);
            }
        }
    }

    &_visibleCafe {
        font-family: $font-stack-secondary, sans-serif;
        #{$p}__wrap {
            width: calc(100% + 1.97vw);
            opacity: 1;

            path {
                transition: 0.2s all ease;
            }

            @media(max-width: 743px) {
                left: -0.9vh;
                width: calc(100% + 2.3vh);
                display: block;
            }
        }

        span {
            transition: 0.2s all ease;
        }

        &:hover {
            #{$p}__wrap {
                path {
                    fill: #fff;
                }
            }

            span {
                color: $color-text-red;
            }
        }
    }

    &_visibleBatya {
        font-family: $font-stack-secondary, sans-serif;
        margin-right: 1.1vw;

        #{$p}__wrap {
            width: calc(100% + 1.97vw);
            opacity: 1;

            path {
                transition: 0.2s all ease;
            }
        }

        span {
            transition: 0.2s all ease;
        }

        &:hover {
            #{$p}__wrap {
                path {
                    fill: #fff;
                }
            }

            span {
                color: #000;
            }
        }

        @media(max-width: 743px) {
            margin-right: 1.4vh;

            #{$p}__wrap {
                left: -0.9vh;
                width: calc(100% + 2.3vh);
                display: block;
            }
        }
    }

    &__wrap {
        position: absolute;
        top: 0;
        left: -0.75vw;
        z-index: 1;
        width: 0;
        height: 100%;
        opacity: 0;
        // overflow: hidden;
        transition: 0.2s width ease, opacity 0.01s 0.2s ease;

        @media(max-width: 743px) {
            display: none;
        }
    }

    &__wrapOverflow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
    }

    &__hoverBg {
        height: 100%;

        &_left {
            margin-right: -1px;
        }

        &_mid {
            width: 105%;
            flex-grow: 1;
        }

        &_right {
            margin-left: -1px;
        }
    }

    span {
        position: relative;
        z-index: 4;
    }
}