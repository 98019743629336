@import '../../assets/scss/core/variables';

.container {
  display: flex;
  &_custom {
    position: relative;
  }
  &__image {
    &_order {
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
  }
}
