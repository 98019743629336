@import 'src/assets/scss/core/variables';

.page {
  background: $color-bg-cold;
  &__header {
    position: static;
  }
  &__footer {
    color: $color-text-dark;
  }
  &__title {
    @media (max-width: 1279px) {
      font-size: 12.09vw;
      line-height: 120%;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 40px 0;
    }

    @media(max-width: 743px) {
      padding: 32px 0 24px;
    }
  }
  &__menu {
    @media (max-width: 1279px) {
      & ul {
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: repeat(2, 1fr);
        position: relative;
        & li {
          &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 6;
          }
          &:nth-child(2) {
            grid-area: 1 / 6 / 2 / 10;
          }
          &:nth-child(3) {
            grid-area: 2 / 1 / 3 / 7;
          }
          &:nth-child(4) {
            grid-area: 2 / 7 / 3 / 10;
          }
          padding-left: 8vw;
          padding-right: 8vw;
          text-align: center;
          & a {
            width: min-content;
          }
        }
      }
    }

    @media(max-width: 743px) {
      & ul {
        grid-template-columns: repeat(10, 1fr);
        & li {
          padding-left: 7vw;
          padding-right: 7vw;

          &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 7;

            & a {
              width: auto;
            }
          }
          &:nth-child(2) {
            grid-area: 1 / 7 / 2 / 11;
          }
          &:nth-child(3) {
            grid-area: 2 / 1 / 3 / 7;
          }
          &:nth-child(4) {
            grid-area: 2 / 7 / 3 / 11;
          }
        }
      }
    }
  }
}
