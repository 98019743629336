@import '../../assets/scss/core/variables';

.page {
  background: $color-bg-warm;
  &__header {
    position: static;
  }
  &__title {
    @media (max-width: $first-breakpoint) {
      font-size: 12.09vw;
      line-height: 120%;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 40px 0;
    }

    @media(max-width: 743px) {
      padding: 32px 0 24px;
    }
  }
  &__footer {
    & span {
      color: $color-text-dark;
    }
  }
  &__slider {
    & div {
      &::before {
        background: $color-bg-warm;
      }
    }
  }
  &__info {
    color: $color-text-warm;
    display: none;

    @media(max-width: 1279px) {
      display: block;
      margin-top: 24px;
      font-size: 4.03vw;
      line-height: 110%;
    }

    @media(max-width: 743px) {
      padding-bottom: 20px;
      font-size: 7.22vw;
      border-bottom: 1px solid #000;
    }
  }
  &__cards {
    grid-auto-flow: column;
    grid-auto-columns: 68.8vh;
    grid-template-rows: repeat(2, 40.5vh);
    align-content: center;
    gap: 10px;

    @media (max-width: 1599px) {
      grid-auto-columns: 443px;
      grid-template-rows: repeat(2, 260px);
      align-self: center;
    }
    @media (max-width: 1439) {
      grid-auto-columns: 433px;
      grid-template-rows: repeat(2, 255px);
    }
    @media (max-width: 1279px) {
      grid-template-columns: 1fr;
      grid-template-rows: none;
      grid-auto-columns: 1fr;
      grid-auto-flow: dense;
      grid-auto-rows: 414px;
      max-width: 700px;
      width: 100%;
      align-self: center;
    }
    @media (max-width: 743px) {
      grid-auto-rows: 190px;
      max-width: 320px;
    }
  }
}
