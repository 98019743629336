@import '../../assets/scss/core/variables';

.page {
  background: $color-bg-red;
  color: $color-text-light;
  padding-bottom: 100px;

  & > main {
    &::-webkit-scrollbar {
      width: 8px;
      height: 10px;
      display: none;

      @media(max-width: 1279px) {
        display: block;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: #fff;
      transition: 0.3s background ease;

      &:hover {
        background: #000;
      }
    }
  }

  @media (max-height: 1000px) {
    padding-bottom: 50px;
  }
  @media (max-height: 800px) {
    padding-bottom: 20px;
  }
  &__header {
    position: static;
  }

  &__title {
    @media (max-width: $first-breakpoint) {
      font-size: 90px;
      line-height: 110px;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 0px 0 40px;
    }
    @media (max-width: $second-breakpoint) {
      font-size: 44px;
      line-height: 44px;
      padding: 0px 0 25px;
    }
    @media (max-width: $third-breakpoint) {
      padding: 0px 0 15px;
    }
  }
}
