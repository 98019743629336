@import 'src/assets/scss/core/variables';
@import 'src/assets/scss/core/extends';

.form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  &__title {
    @extend %title-block;
    height: 110px;
    overflow-y: hidden;
    & > span {
      display: block;
      transform: translateY(200%);
      animation: TitleAppear 0.7s ease-in 0.7s forwards;
    }
    @media (max-width: $first-breakpoint) {
      margin-bottom: 10px;
    }
    @media (max-width: 830px) {
      font-size: 100px;
      line-height: 98px;
    }
    @media (max-width: 768px) {
      font-size: 80px;
      line-height: 82px;
      height: 85px;
    }
    @media (max-width: 678px) {
      font-size: 44px;
      line-height: 44px;
      margin-bottom: 5px;
      height: 50px;
    }
  }
  &__point {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;
    @media (max-width: 768px) {
      justify-content: flex-start;
    }
    @media (max-width: 678px) {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 40px;
    }
  }
  &__block {
    display: flex;
    margin-bottom: auto;
    @media (max-width: 1230px) {
      flex-direction: column;
    }
  }
  &__group {
    flex: 1 1 auto;
    &:first-of-type {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      column-gap: calc(70px + 5vw);
      place-items: center;
      margin-bottom: auto;
      @media (max-width: 1440px) {
        column-gap: calc(20px + 5vw);
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
      }
    }
    &:last-of-type {
      display: flex;
      margin-top: auto;
    }
    &_name {
      grid-area: 1 / 1 / 2 / 2;
      @media (max-width: 768px) {
        grid-area: 1 / 1 / 2 / 2;
        margin-bottom: 40px;
      }
      & input {
        @media (max-width: 678px) {
          font-size: 26px;
          line-height: 29px;
          &::placeholder {
            font-size: 26px;
            line-height: 29px;
          }
        }
      }
    }
    &_number {
      margin-top: auto;
      grid-area: 2 / 1 / 3 / 2;
      @media (max-width: 768px) {
        grid-area: 2 / 1 / 3 / 2;
        margin-bottom: 60px;
      }
      & input {
        @media (max-width: 678px) {
          font-size: 26px;
          line-height: 29px;
          &::placeholder {
            font-size: 26px;
            line-height: 29px;
          }
        }
      }
    }
    &_socials {
      width: 100%;
      grid-area: 1 / 2 / 3 / 3;
      @media (max-width: 768px) {
        grid-area: 3 / 1 / 4 / 2;
      }
    }
    &_text {
      width: 100%;
      margin-left: calc(70px + 5vw);
      height: auto;
      @media (max-width: 1440px) {
        margin-left: calc(20px + 5vw);
      }
      @media (max-width: 1230px) {
        margin: 0;
        margin-top: 80px;
        margin-bottom: 40px;
      }
      & textarea {
        @media (max-width: 678px) {
          font-size: 26px;
          line-height: 29px;
          &::placeholder {
            font-size: 26px;
            line-height: 29px;
          }
        }
      }
    }
  }
  &__success {
    position: absolute;
    font-family: $font-stack-secondary;
    font-weight: 400;
    font-size: calc(70px + 5vw);
    line-height: 92px;
    text-transform: none;
    width: 100vw;
    height: var(--app-height);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: currentColor;
    color: currentColor;
    transform: translateX(-2%);
    animation: submitSuccess 0.5s ease-in forwards;
    text-align: center;
    @media (max-width: 500px) {
      transform: translateX(-5%);
      font-size: calc(30px + 5vw);
      line-height: 45px;
    }
    &_red {
      color: $color-text-light;
      background-color: $color-bg-red;
    }
    &_black {
      color: $color-text-light;
      background-color: $color-bg-dark;
    }
    &_cold {
      color: $color-text-dark;
      background-color: $color-bg-cold;
    }
  }
}

@keyframes submitSuccess {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes TitleAppear {
  to {
    transform: translateY(0%);
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 45px;
  @media (max-width: 1300px) {
    padding: 10px 0 25px;
  }
  @media (max-width: 678px) {
    flex-direction: column;
  }
  &__text {
    align-self: flex-end;
    @media (max-width: 1300px) {
      max-width: 890px;
      margin-right: 10px;
    }
    @media (max-width: 678px) {
      max-width: 100%;
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 18px;
    }
    &_link {
      border-bottom: 2px solid currentColor;
      padding-bottom: 0px;
      line-height: 25px;
    }
  }
  & button[type='submit'] {
    @media (max-width: 768px) {
      font-size: 54px;
      line-height: 56px;
    }
    @media (max-width: 678px) {
      width: fit-content;
      font-size: 24px;
      line-height: 26px;
      align-self: center;
      &::after {
        height: 3px;
      }
    }
  }
}
