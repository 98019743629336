@import '../../../assets/scss/core/variables';

.next {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
  min-height: 100%;
  // &::before {
  //   content: '';
  //   position: absolute;
  //   width: 30px;
  //   height: 30px;
  //   background: rgb(253, 0, 0);

  //   top: 0;
  //   right: 0;
  // }
  // &::after {
  //   content: '';
  //   width: 30px;
  //   height: 30px;
  //   background: rgb(253, 0, 0);
  //   position: absolute;

  //   bottom: 0;
  //   right: 0;
  // }
  @media (max-width: $first-breakpoint) {
    min-height: fit-content;
    padding: 10px 0;
    flex-direction: row-reverse;
    align-content: center;
    justify-content: center;
  }
  &__container {
    pointer-events: none;
  }
  &__link {
    font-size: 1.56vw;
    line-height: 110%;
    padding: 10px;
    opacity: 0.3;
    transition: opacity 0.4s ease-in;
    font-weight: 600;

    @media (max-width: $first-breakpoint) {
      writing-mode: vertical-rl;
      padding: 0;
      font-size: 2.7vw;
      line-height: 100%;
      text-align: center;
    }

    @media(max-width: 743px) {
      font-size: 5vw;
    }

    &:hover {
      opacity: 1;
    }
  }
  &__title {
    display: flex;
    padding: 0;
    font-size: 6.7vh;
    line-height: 120%;
    text-transform: lowercase;

    @media (max-width: 1279px) {
      font-size: 9.94vw;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 0;
      margin: auto;
      margin-left: 0;
    }

    @media(max-width: 743px) {
      font-size: 10.55vw;
    }
  }
}
