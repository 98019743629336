@import '../../../../assets/scss/core/variables';

.vine_cheese__nav_item {
  align-items: flex-start;
  word-break: break-word;
  &:nth-last-child(-n + 2) {
    .nav_item__title {
      margin: 0;
    }
  }

  .nav_item__list {
    align-items: flex-start;
  }
  .nav_item__title {
    &:hover {
      color: $color-text-red;
    }
  }
  @media (max-width: $third-breakpoint) {
    font-size: 15px;
    line-height: 15px;
  }
}

.batya29__nav_item {
  align-items: flex-end;

  & > div {
    align-items: flex-end;
  }

  &:first-child {
    margin: 0 auto;
    @media (max-width: 1720px) {
      margin: 0;
    }
  }

  .nav_item__list {
    align-items: flex-end;
  }
  .nav_item__title {
    &:hover {
      color: $color-text-dark;
    }
  }
}

.nav_item {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  &:not(:last-child) {
    margin-bottom: 4.78vh;
  }

  @media (max-width: 1279px) {
    &:not(:last-child) {
      margin-bottom: 5.648vh;
    }
    &:nth-of-type(3) {
      margin-bottom: 2.82vh;
    }
  }

  @media (max-width: 743px) {
    &:not(:last-child) {
      // margin-bottom: 5.276vh;
      margin-bottom: calc(var(--app-height) * 5.276 / 100);
    }
    &:nth-of-type(3) {
      // margin-bottom: 3.05vh;
      margin-bottom: calc(var(--app-height) * 3.05 / 100);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 3.12vw;
    line-height: 100%;
    margin-bottom: 2.65vh;
    position: relative;
    font-variation-settings: 'GRAD' -200, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 34;
    font-stretch: 40%;
    z-index: 2;
    transition: color 0.2s ease-in;
    width: fit-content;
		
		@media (max-width: 1366px) {
      margin-bottom: 2vh;
      font-size: 3vw;
		}

    @media (max-width: 1279px) {
      font-size: 2.68vw;
      margin-bottom: 2.118vh;
    }

    @media (max-width: 743px) {
      // font-size: 2.77vh;
      font-size: calc(var(--app-height) * 2.77 / 100);
      // margin-bottom: 2.22vh;
      margin-bottom: calc(var(--app-height) * 2.22 / 100);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    font-size: 1.56vw;
    line-height: 100%;

    @media(max-width: 1279px) {
      font-size: 2.68vw;
    }

    @media(max-width: 743px) {
      // font-size: 2.5vh;
      font-size: calc(var(--app-height) * 2.5 / 100);
      line-height: 120%;
    }

    &_item {
      position: relative;
      width: fit-content;
      cursor: pointer;
      transition: opacity 0.2s ease-in;
      &:not(:last-child) {
        margin-bottom: 3.4vh;
      }

      &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 0;
        bottom: -2px;
        left: 0;
        background-color: $color-text-light;
        transition: width 0.3s ease;
      }
      &:hover {
        &::after {
          width: 100%;
        }
      }

      @media(max-width: 1279px) {
        letter-spacing: 0.02em;

        & > a {
          opacity: 0.5;
        }

        &:after {
          width: 100%;
          height: 1px;
          opacity: 0.5;
        }

        &:not(:last-child) {
          margin-bottom: 2.82vh;
        }
      }

      @media(max-width: 743px) {
        text-decoration: underline;
        text-underline-offset: 3px;

        &::after {
          display: none;
        }
      }
    }
  }
  &__logo {
    display: flex;
    margin-top: 3.4vh;
  }
}
