@import 'src/assets/scss/core/variables';

.intro {
  display: flex;
  min-height: 100%;
  padding: 0 64px 0 24px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background: $color-bg-light;
    transform: translateY(100%);
    transition: transform 0.7s ease-in;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: $color-bg-light;
    transform: translateY(100%);
    transition: transform 0.7s ease-in;
  }
  @media (max-width: $first-breakpoint) {
    &::before,
    &::after {
      content: none;
    }
    @media (max-width: $first-breakpoint) {
      &::after {
        top: 100%;
        height: 1px;
        width: 100%;
        transform: translateX(100%);
      }
    }
    flex-direction: row-reverse;
    padding: 0;
    margin-bottom: 40px;
  }

  @media (max-width: 1279px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    grid-template-areas: "text video";
    min-height: auto;
  }

  @media (max-width: 743px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    min-height: auto;
  }

  &_active {
    &::after {
      transform: translateY(0%);
      @media (max-width: $first-breakpoint) {
        transform: translateX(0%);
      }
    }
    &::before {
      transform: translateY(0%);
      @media (max-width: $first-breakpoint) {
        transform: translateX(0%);
      }
    }
  }

  &__cursor {
    position: absolute;
    width: 108px;
    height: 108px;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.3s ease;
    pointer-events: none;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: top;
    }

    &_show {
      transform: translate(-50%, -50%) scale(1);
    }

    @media(max-width: 1279px) {
      top: auto !important;
      left: auto !important;
      right: 22px;
      bottom: 22px;
      width: 100px;
      height: 100px;
      transform: translate(0, 0) scale(1);
    }
  }

  &__video {
    position: relative;
    width: 27.865vw;
    height: 100%;
    grid-area: video;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 105%;
      transition: height 1s ease-in 0.7s;
      background-color: $color-bg-dark;
    }
    @media (max-width: $first-breakpoint) {
      &::after {
        width: 100%;
        transition: width 1s ease-in 0.5s;
        background-color: $color-bg-dark;
      }
    }
    &_active {
      &::after {
        height: 0px;
      }
      @media (max-width: $first-breakpoint) {
        &::after {
          width: 0px;
          height: 101%;
        }
      }
    }
    & video {
      height: auto;
      object-fit: cover;
      aspect-ratio: 5/8;
    }
    @media (max-height: 900px) and (min-width: $first-breakpoint) {
      min-width: 450px;
    }
    @media (max-height: 800px) and (min-width: $first-breakpoint) {
      min-width: 400px;
    }
    @media (max-width: $first-breakpoint) {
      width: 100%;
      margin-right: 0;
      display: flex;
      & video {
        margin-left: auto;
        object-fit: cover;
      }
    }
    @media (max-width: 800px) {
      min-width: fit-content;
      align-self: flex-end;
      & video {
        aspect-ratio: auto;
        object-fit: contain;
      }
    }
    @media (max-width: 600px) {
      min-width: 100%;
      & video {
        aspect-ratio: 6/9;
        object-fit: fill;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: 23.44vw;
    margin-left: 80px;
    flex-shrink: 0;
    @media (max-width: $first-breakpoint) {
      min-width: auto;
      width: 100%;
      margin: 0 25px 0 0;
    }
    @media (max-width: 600px) {
      margin-top: 24px;
    }
  }
}

.content {
  &_active {
    .content {
      &__title {
        opacity: 0;
        transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
        animation: TextAnimation 0.7s ease forwards;
      }
      &__info {
        &_main {
          opacity: 0;
          transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
          animation: TextAnimation 0.5s 0.2s ease-in forwards;
        }
        &_extra {
          opacity: 0;
          transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
          animation: TextAnimation 0.5s 0.4s ease-in forwards;
        }
      }
    }
  }
  &__title {
    margin-bottom: 65px;
    text-transform: none;
    font-family: $font-stack-secondary;
    font-weight: 400;
    font-size: 3.12vw;
    line-height: 120%;

    @media (max-width: $first-breakpoint) {
      width: 70%;
      margin-left: auto;
      margin-bottom: auto;
      text-align: end;
      font-size: 8.06vw;
    }

    @media (max-width: $second-breakpoint) {
      width: 100%;
      margin: 0;
      margin-bottom: auto;
    }
    @media (max-width: 600px) {
      margin-bottom: 25px;
    }
  }
  &__info {
    &_main {
      font-size: 1.56vw;
      line-height: 110%;
      margin-bottom: 38px;

      @media (max-width: $first-breakpoint) {
        margin-bottom: 16px;
        font-size: 4.03vw;
      }

      @media(max-width:743px) {
        font-size: 7.2vw;
      }
    }
    &_extra {
      text-transform: none;
      max-width: 14.06vw;
      font-size: 1.04vw;
      line-height: 110%;

      @media (max-width: $first-breakpoint) {
        max-width: 100%;
        font-size: 2.7vw;
        color: rgba(#fff, 0.4);
      }

      @media (max-width: 743px) {
        font-size: 3.87vw;
      }
    }
  }
}

@keyframes TextAnimation {
  from {
    opacity: 0;
    transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
