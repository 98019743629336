@import 'src/assets/scss/core/variables';

.jobs {
  display: grid;
  grid-auto-flow: column dense;
  grid-template-rows: 1fr;
  // grid-auto-columns: 720px;
  grid-auto-columns: 37.5vw;
  margin-right: 20px;
  position: relative;
  @media (max-width: $first-breakpoint) {
    display: flex;
    flex-direction: column;
    margin-right: 0;
    &::after {
      height: 1px;
      width: 100%;
    }
    &::before {
      content: '';
      top: 100%;
      height: 1px;
      width: 100%;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0 40px;
    position: relative;
    &:first-child {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        transform: translateY(100%);
        transition: transform 0.7s ease-in;
        background: $color-bg-light;
        @media (max-width: $first-breakpoint) {
          height: 1px;
          width: 100%;
          transform: translateX(100%);
        }
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      transform: translateY(100%);
      transition: transform 0.7s ease-in;
      background: $color-bg-light;
      @media (max-width: $first-breakpoint) {
        transform: translateX(100%);
      }
    }
    &_active {
      &:first-child {
        &::before {
          transform: translateY(0%);
        }
      }
      &::after {
        transform: translateY(0%);
      }
      .content__title {
        transform: translate(-50%, -50%) skew(0, 0) scale(1, 1);
        opacity: 1;

        @media(max-width: 1279px) {
          transform: translate(0, 0) skew(0, 0) scale(1, 1);
        }
      }
      .content__info {
        transform: translate(0, 0) skew(0, 0) scale(1, 1);
        opacity: 1;
      }
    }

    @media (max-width: $first-breakpoint) {
      &::after {
        height: 1px;
        width: 100%;
        top: 100%;
      }
      padding: 68px 0 41px;
    }
    @media (max-width: $second-breakpoint) {
      padding: 30px 0;
    }
  }
}

.content {
  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 3.12vw;
    line-height: 120%;
    margin: auto 0;
    width: fit-content;
    cursor: pointer;
    transform-origin: 50% 50%;
    transform: translate(0, 70%) skew(-25deg, 0) scale(1, 1.3);
    opacity: 0;
    transition: transform 0.7s ease-in, opacity 0.5s ease-in 0.3s;

    &::after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 0;
      height: 5px;
      width: 0%;
      transition: width 0.3s ease;
      background: $color-bg-light;
      @media (max-width: $second-breakpoint) {
        bottom: 4px;
        height: 3px;
      }
      @media (max-width: $third-breakpoint) {
        bottom: 2px;
        height: 1px;
      }
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }

    @media (max-width: 1279px) {
      position: relative;
      top: auto;
      left: auto;
      margin: 0;
      margin-bottom: 64px;
      font-size: 8.06vw;

      &::after {
        width: 100%;
      }
    }
    @media (max-width: 743px) {
      font-size: 6.1vw;
      margin-bottom: 32px;
    }
  }
  &__info {
    font-size: 1.04vw;
    line-height: 120%;
    font-weight: 400;
    text-transform: none;
    transform-origin: 50% 50%;
    transform: translate(0, 50%) skew(-30deg, 0) scale(1, 1.3);
    opacity: 0;
    transition: transform 0.7s ease-in, opacity 0.5s ease-in 0.3s;
    font-variation-settings: normal;

    @media (max-width: 1279px) {
      font-size: 2.7vw;
      padding: 0 10vw;
    }

    @media (max-width: 743px) {
      padding: 0;
      font-size: 3.9vw;
    }
  }
  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 calc(300px - 5vw);
    font-size: 60px;
    line-height: 60px;
    text-align: center;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background: $color-bg-light;
    }
    @media (max-width: 1200px) {
      padding: 0;
    }
    @media (max-width: $first-breakpoint) {
      margin: auto 0;
      font-size: 45px;
      line-height: 45px;
      &::after {
        content: none;
      }
    }
    @media (max-width: $second-breakpoint) {
      font-size: 35px;
      line-height: 38px;
    }
    @media (max-width: $third-breakpoint) {
      font-size: 25px;
      line-height: 25px;
    }
  }
}

.modal {
  &_enter_active {
    animation: ModalApper 0.5s ease-in;
    & > div {
      opacity: 0;
      animation: ModalOpacity 0.7s ease-in 0.5s forwards;
    }
  }
  &_exit_active {
    animation: ModalDisapper 0.5s ease-in forwards;
  }
}

@keyframes ModalApper {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes ModalOpacity {
  to {
    opacity: 1;
  }
}

@keyframes ModalDisapper {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
