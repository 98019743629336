@import '../../../assets/scss/core/variables';

.advertising {
  min-height: 100%;
  display: grid;
  grid-template-columns: 45.83vw 21.356vw 45.83vw;
  padding: 0 80px;
  font-size: 1.56vw;
  line-height: 110%;
  gap: 80px;

  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
    padding: 25px 0;
    gap: 40px;
    min-height: fit-content;
  }
}