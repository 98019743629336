@import '../../assets/scss/core/variables';

.map {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100vw;
  height: var(--app-height);

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 39.38vh;
    width: 100%;
    background-color: #fff;

    @media (max-width: 1279px) {
      height: 36.1%;
    }
    @media (max-width: 743px) {
      height: 59.44%;
    }
  }

  &__inner {
    display: flex;
    width: 100%;
    height: calc(100% - 39.38vh);

    @media (max-width: 1279px) {
      height: calc(100% - 36.1%);
    }
    @media (max-width: 743px) {
      height: calc(100% - 59.44%);
    }
  }
}

.mapBlock {
  $p: &;
  display: flex;

  &__side {
    padding: calc(88px + 5vh) 25px 0;
    width: 50%;
    height: 100%;

    @media (max-width: 1279px) {
      padding: calc(88px + 2.12vh) 25px 0;
    }
    @media (max-width: 743px) {
      padding: calc(68px + 3.9vh) 10px 0;
    }

    &_left  {
      background-color: $color-bg-red;
    }

    &_right {
      background-color: $color-bg-dark;

      #{$p} {
        &__list {
          justify-content: flex-end;
          text-align: right;
        }

        @media(max-width: 1279px) {
          &__subtitle {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  &__list {
    display: flex;

    @media (max-width: 1279px) {
      flex-direction: column;
    }
  }

  &__item {
    margin-right: 4.58vw;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 1279px) {
      margin: 0 0 2.91vh;
    }
    @media (max-width: 743px) {
      margin: 0 0 3.9vh;
    }
  }

  &__subtitle {
    margin-bottom: 2.5vh;
    text-transform: uppercase;
    font-size: 1.04vw;
    line-height: 100%;
    font-weight: 400;

    span {
      opacity: 0.3;
    }

    @media (max-width: 1279px) {
      margin-bottom: 1.41vh;
      font-size: 2.7vw;
      display: flex;
      span {
        margin: 0 15px 0 0;
      }
    }
    @media (max-width: 743px) {
      margin-bottom: 1.67vh;
      // по макету 5
      // font-size: 5vw;
      font-size: 4.3vw;
      display: block;
      span {
        display: block;
        opacity: 1;
      }
    }
  }

  &__link {
    position: relative;
    font-size: 1.56vw;
    line-height: 110%;
    font-stretch: 40%;

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 0;
      bottom: -2px;
      left: 0;
      background-color: $color-text-light;
      transition: width 0.3s ease;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }

    @media (max-width: 1279px) {
      font-size: 2.7vw;

      &::after {
        width: 100%;
      }
    }
    @media (max-width: 743px) {
      // по макету 5
      // font-size: 5vw;
      font-size: 4.3vw;
    }
  }

  &__footer {
    position: absolute;
    bottom: 2.1vh;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 50px);

    @media (max-width: 743px) {
      width: calc(100% - 20px);
    }
  }
}

.footer {
  $p: &;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  &__block {
    &_middle {
      display: flex;
      justify-content: center;
    }

    &_right {
      display: flex;
      justify-content: flex-end;
    }

    @media (max-width: 1279px) {
      display: grid;

      &_left {
        #{$p} {
          &__list {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            gap: 24px 76px;
          }
          &__item {
            margin: 0;
          }
        }
      }
      &_middle {
        align-items: end;
      }
      &_right {
        #{$p} {
          &__list {
            display: grid;
            grid-template-rows: auto;
            gap: 24px 46px;
          }
          &__item {
            margin: 0;
            justify-self: end;
          }
        }
      }
    }

    @media (max-width: 743px) {
      display: flex;

      &_left {
        #{$p} {
          &__list {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
      &_right {
        #{$p} {
          &__list {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }
  }

  &__list {
    display: flex;
  }

  &__item {
    margin-right: 32px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    position: relative;
    font-size: 1.56vw;
    line-height: 110%;
    text-transform: uppercase;
    // text-decoration: underline;

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 0;
      bottom: -2px;
      left: 0;
      background-color: $color-text-light;
      transition: width 0.3s ease;
    }
    &:hover {
      // opacity: 0.6;
      &::after {
        width: 100%;
      }
    }

    @media (max-width: 1279px) {
      font-size: 2.7vw;

      &::after {
        width: 100%;
      }
    }
    @media (max-width: 743px) {
      // по макету 5
      // font-size: 5vw;
      font-size: 4.3vw;
    }
  }
}

.mapContent {
  position: absolute;
  top: 39.38vh;
  left: 50%;
  transform: translateX(-50%);
  height: calc(100% - 39.38vh);
  width: calc(100% - 50px);

  @media (max-width: 1279px) {
    height: calc(100% - 36.1%);
  }
  @media (max-width: 743px) {
    height: calc(100% - 59.44%);
  }

  &__address {
    position: absolute;
    left: 25px;
    top: calc(39.38vh + 32px);
    width: 15.63vw;

    p {
      font-size: 1.56vw;
      line-height: 110%;
      opacity: 0.5;
    }

    &_right {
      left: auto;
      right: 25px;
      text-align: right;
    }

    @media (max-width: 1279px) {
      top: calc(36.1% + 32px);
      width: 27.55vw;
      p {
        font-size: 2.7vw;
      }
    }
    @media (max-width: 743px) {
      top: calc(59.44% + 16px);
      left: 10px;
      width: 27.55vw;
      p {
        // по макету 5
        // font-size: 5vw;
        font-size: 4.3vw;
      }

      &_right {
        left: auto;
        right: 10px;
      }
    }
  }

  &__btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(39.38vh + 32px);
    display: flex;
    font-weight: 400;

    span {
      font-size: 1.56vw;
      line-height: 110%;
    }

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 0;
      bottom: -2px;
      left: 0;
      background-color: $color-text-light;
      transition: width 0.3s ease;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }

    @media (max-width: 1279px) {
      top: calc(36.1% + 32px);

      span {
        font-size: 2.7vw;
        font-weight: 400;
      }
      
      &::after {
        width: 100%;
      }
    }

    @media (max-width: 743px) {
      top: calc(59.44% + 16px);
      width: 27.55vw;

      span {
        // по макету 5
        // font-size: 5vw;
        font-size: 4.3vw;
        text-decoration: underline;
      }

      &:after {
        display: none;
      }
    }
  }

  &__link {
    position: absolute;
    bottom: 38px;
    left: 25px;
    display: block;
    font-size: 3.125vw;
    line-height: 120%;
    width: 25.8vw;

    span {
      background-image: linear-gradient(#fff, #fff);
      background-size: 0 0.1em, 100% 0.1em;
      background-position: 0 100%, 0 100%;
      background-repeat: no-repeat;
      transition: background-size 0.3s;
  
      &:hover {
        background-size: 100% 0.1em, 100% 0.1em;
      }
    }

    &_right {
      left: auto;
      right: 25px;
      text-align: right;
    }

    @media (max-width: 1279px) {
      width: 50%;
      font-size: 8.07vw;

      span {
        background-size: 100% 0.1em, 100% 0.1em;
      }
    }

    @media (max-width: 743px) {
      bottom: 16px;
      left: 10px;
      width: 40%;
      font-size: 6.1vw;

      &_right {
        left: auto;
        right: 10px;
      }
    }
  }
}

.modal {
  &_enter_active {
    animation: ModalApper 0.5s ease-in;
    & > div {
      opacity: 0;
      animation: ModalOpacity 0.7s ease-in 0.5s forwards;
    }
  }
  &_exit_active {
    animation: ModalDisapper 0.5s ease-in forwards;
  }
}

@keyframes ModalApper {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes ModalOpacity {
  to {
    opacity: 1;
  }
}

@keyframes ModalDisapper {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}