@import '/src/assets/scss/core/variables';

.modal {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--app-height);
  width: 100vw;
  display: flex;
  overflow-y: overlay;
  overflow-x: hidden;
  z-index: 1000;
  background: $color-bg-cold;
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background: #fff;
    transition: 0.3s all ease;

    &:hover {
      background: #000;
    }
  }

  &_red {
    background: $color-bg-red;
    color: $color-text-light;
  }
  &_black {
    background: $color-bg-dark;
    color: $color-text-light;

    &::-webkit-scrollbar-thumb {
      &:hover {
        background: $color-bg-red;
      }
    }
  }
  &_cold {
    background: $color-bg-cold;
    color: $color-text-dark;

    &::-webkit-scrollbar-thumb {
      background: #000;
  
      &:hover {
        background: $color-bg-red;
      }
    }
  }
  &__wrapper {
    margin: 0 auto;
    width: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    @media (max-width: 375px) {
      width: calc(100% - 20px);
    }
  }
  &__header {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    &_button {
      cursor: pointer;
    }
    & div,
    button svg {
      @media (max-width: 678px) {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.vineBg {
  fill: $color-bg-red;
}

.vineText {
  fill: #000;
}