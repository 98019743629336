@import '../../../assets/scss/core/variables';

.page_title {
  font-family: $font-stack-secondary;
  // min-height: 100%;
  padding: 0 24px 0 36px;
  font-size: 10vh;
  line-height: 100%;
  writing-mode: vertical-rl;
  font-weight: 400;
  transform: rotate(-180deg);
  text-transform: lowercase;

  // @media (min-width: 1921px) {
  //   font-size: 90px;
  //   line-height: 110px;
  // }
}
