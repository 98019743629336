@import '../../../../../assets/scss/core/variables';


.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:first-child {
      & .content__info {
        max-width: 20.83vw;
        align-self: start;
        @media (max-height: 1050px) and (min-width: $first-breakpoint) {
          margin: auto 0;
        }
        @media (max-width: $first-breakpoint) {
          max-width: 50%;
          margin-top: 25px;
        }
        @media (max-width: $second-breakpoint) {
          max-width: 100%;
        }
      }
      & .content__container {
        height: 58.51vh;

        @media(max-width:743px) {
          height: 63.3vw;
        }
      }
    }
    &:nth-child(2) {
      @media (max-width: $first-breakpoint) {
        max-width: 50%;
        margin-left: auto;
      }
      @media (max-width: $second-breakpoint) {
        max-width: 100%;
        margin: 0;
      }
      & .content__info {
        @media (max-height: 1050px) and (min-width: $first-breakpoint) {
          margin: auto 0;
        }
      }
      & .content__container {
        height: 58.51vh;
      }
    }
    &:last-child {
      font-family: $font-stack-secondary;
      font-weight: 400;
      font-size: 3.12vw;
      line-height: 120%;
      flex-direction: column-reverse;
  
      @media (max-width: $first-breakpoint) {
        margin-top: 40px;
      }

      @media(max-width: 743px) {
        margin: 0;
      }
      & .content__info {
        font-size: 3.12vw;
        line-height: 120%;
        margin-top: 0;
        margin-bottom: 50px;

        @media (max-width: $first-breakpoint) {
          font-size: 9.94vw;
          line-height: 120%;
          text-align: start;
          margin-bottom: 32px;
          text-transform: none;
        }

        @media (max-width: $second-breakpoint) {
          font-size: 38px;
          line-height: 46px;
          text-align: end;
        }
      }
      & .content__container {
        @media (max-height: 1050px) and (min-width: $first-breakpoint) {
          height: auto;
        }
      }
    }
    &__container {
        position: relative;
      width: 100%;
      height: 100%;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transition: height 1s ease-in;
        background-color: $color-bg-warm;
      }
      @media (max-width: $first-breakpoint) {
        &::after {
          width: 100%;
          transition: width 1s ease-in;
          background-color: $color-bg-warm;
        }
      }
      &_active {
        &::after {
          height: 0px;
        }
        @media (max-width: $first-breakpoint) {
          &::after {
            width: 0px;
            height: 100%;
          }
        }
      }
      &_image {
        object-fit: cover;
        width: 100%;
      }
    }
    &__info {
      margin-top: 40px;
      transform-origin: 0% 50%;
      transform: translate(0, 25%) skew(-15deg, 0) scale(1, 1.2);
      opacity: 0;
      transition: transform 1s ease-in, opacity 0.5s ease-in 0.5s;
      & span {
        color: $color-text-red;
      }
      &_active {
        opacity: 1;
        transform: translate(0, 0%) skew(0, 0) scale(1, 1);
      }
      @media (max-width: $first-breakpoint) {
        font-size: 4.03vw;
        line-height: 110%;

        span {
          display: block;
        }
      }
      @media (max-width: $second-breakpoint) {
        font-size: 7.22vw;
      }
    }
  }