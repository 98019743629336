@import '../../../../assets/scss/core/variables';

.slide {
	position: relative;
	height: 100%;

	@media(max-width: 1279px) {
		position: static;

		&_padding {
			padding: 4px 0;
		}
	}

	&__slideInfo {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 21;

		&_batya {
			left: auto;
			right: 0;
		}

		@media(max-width: 1279px) {
			display: none;
		}
	}

	&__img {
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&_batya {
			transform: translateX(-40%);
			transition: 0.5s transform ease;
		}

		&_full {
			transform: translateX(0);
		}

		@media(max-width: 743px) {
			transform: translateX(0);
		}
	}

	&__btn {
		position: absolute;
		top: 0;
		width: 50%;
		height: 100%;
		z-index: 2;
		cursor: pointer;
		display: none;

		&_prev {
				left: 0;
		}

		&_next {
				right: 0;
		}

		&_show {
			display: block;
		}

		@media(max-width: 1279px) {
			display: none;
		}
	}

	&__close {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;
		z-index: 3;
		padding: 0;
		background-color: transparent;
		border: 0;
		outline: 0;
		cursor: pointer;
		width: 3.33vw;
		height: 3.33vw;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.3s ease;

		&_show {
			opacity: 1;
			visibility: visible;
		}

		@media(max-width: 1279px) {
			display: none;
		}

		svg {
			width: 100%;
			height: 100%;
			object-fit: contain;
			vertical-align: top;
		}
	}
}

.slideInfo {
	$p: &;
	padding: 1.25vw 1.302vw 1.93vw;
	width: 26.25vw;
	background-color: $color-bg-red;
	transform: translateY(100%);
	transition: 0.3s all ease;

	// &_show {
	// 	transform: translateY(calc(100% - 4.8vw));
	// }

	&_open {
		transform: translateY(0) !important;

		#{$p} {
			&__arrow {
				transform: rotate(0);
			}
		}
	}

	&_batya {
		background-color: #000;
	}

	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1.83vw;
		cursor: pointer;
	}

	&__title {
		font-family: $font-stack-secondary;
		color: #fff;
		font-weight: 400;
		font-size: 2.083vw;
		line-height: 120%;
	}

	&__descr {
		p {
			font-weight: 400;
			font-size: 1.04vw;
			line-height: 120%;
			color: #fff;
			text-transform:none;
		}

		@media(max-width: 1279px) {
			position: absolute;
			left: 0;
			bottom: 0;
			padding: 0 0 32px 25px;
			width: 67vw;

			p {
				font-size: 1.24vh;
				font-variation-settings: normal;
				font-stretch: normal;
			}

			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: -1;
				width: 100vw;
				height: 146px;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.433244) 28.77%, rgba(0, 0, 0, 0.751563) 60.7%, #000000 91.79%);
			}
		}
	}

	&__arrow {
		transform: rotate(180deg);
		transition: transform 0.3s ease;
	}
}