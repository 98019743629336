@import "../../assets/scss/core/variables";

.page {
  background: $color-bg-warm;
  &__content {
    // padding-right: 210px;

    & > section {
      padding-right: 210px;
    }

    @media(max-width:1279px) {
      margin: 0;
      padding: 0 16px;
      width: 100%;

      & > section {
        padding-right: 0;
      }
    }
  }
  &__header {
    position: static;
  }
  &__footer {
    & span {
      color: $color-text-dark;
    }
  }
  &__title {
    @media (max-width: 1279px) {
      font-size: 12.09vw;
      line-height: 120%;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 40px 0;
    }
    @media (max-width: 732px) {
      line-height: 100%;
      letter-spacing: -0.02em;
      padding: 25px 0;
    }
  }
  &__slider {
    & div {
      &::before {
        background: $color-bg-warm;
      }
    }

    @media (max-width: 1279px) {
      margin-bottom: 32px;
    }
  }
  &__info {
    color: $color-text-warm;
    display: none;

    @media(max-width: 1279px) {
      display: block;
      margin-bottom: 64px;
      font-size: 4.03vw;
      line-height: 110%;
    }

    @media(max-width: 743px) {
      padding-bottom: 20px;
      font-size: 7.22vw;
      border-bottom: 1px solid #000;
    }
  }
  &__cards {
    grid-auto-flow: column dense;
    grid-auto-columns: 67.7vh;
    grid-template-rows: repeat(1, 82.13vh);
    gap: 16px;
    place-content: center;
    flex-shrink: 0;

    // @media (max-width: 1600px) {
    //   grid-auto-columns: 489px;
    //   grid-template-rows: repeat(1, 594px);
    // }

    @media (max-width: 1440px) {
      grid-auto-columns: 408px;
      grid-template-rows: repeat(1, 496px);
    }
    @media (max-width: 1279px) {
      grid-auto-columns: 374px;
      grid-template-rows: repeat(2, 220px);
      gap: 0;
    }
    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-template-rows: none;
      grid-auto-columns: 1fr;
      grid-auto-flow: dense;
      grid-auto-rows: 414px;
      max-width: 700px;
      width: 100%;
      align-self: center;
    }
    @media (max-width: 743px) {
      grid-auto-rows: 190px;
      max-width: 320px;
    }
  }
  &__next {
    padding: 105px 57px;
    background: $color-bg-dark;
    color: $color-text-light;
    width: 201px;
    position: fixed;
    left: calc(100% + 1px);
    bottom: 0;

    @media (max-width: $first-breakpoint) {
      margin-top: 35px;
    }
    & h1 {
      font-family: $font-stack-primary;
      text-transform: uppercase;

      font-size: 100px;
    }

    @media (max-width: $first-breakpoint) {
      & h1 {
        margin-left: auto;
        margin-right: 0;
        padding: 20px;
      }
      & a {
        display: none;
      }
    }
    @media (max-width: $second-breakpoint) {
      & h1 {
        padding: 10px;
        font-size: 60px;
      }
    }
    @media (max-width: 1279px) {
      z-index: 2;
      padding: 30px 20px;
      margin-left: -16px;
      width: calc(100% + 32px);
      position: initial;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      & div {
        width: 400px;
      }
    }
    @media (max-width: $second-breakpoint) {
      padding: 20px 20px;
      & div {
        width: 300px;
      }
    }
    @media (max-width: $third-breakpoint) {
      padding: 15px 20px;
      & div {
        width: 200px;
      }
    }
    @media (max-width: 360px) {
      & div {
        width: 200px;
      }
    }
  }
}
