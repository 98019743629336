@import "../../../../assets/scss/core/variables";

.section {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;

	&__container {
		&::before {
			content: "";
			position: fixed;
			top: 0;
			left: 0;
			z-index: 2;
			width: 50%;
			height: 88px;
			background-color: $color-bg-red;
			opacity: 0;
		}

		&::after {
			content: "";
			position: fixed;
			top: 0;
			right: 0;
			z-index: 2;
			width: 50%;
			height: 88px;
			background-color: #000;
			opacity: 0;
		}

		@media (max-width: 743px) {
			&::after,
			&::before {
				height: 68px;
			}
		}

		img {
			opacity: 0;
			transition: opacity 0.7s ease-in 0.25s;
		}

		&_inActiveCafe {
			&:before {
				opacity: 1;
				transition: opacity 0.6s ease-in 0.7s;
			}
		}

		&_inActiveBatya {
			&:after {
				opacity: 1;
				transition: opacity 0.6s ease-in 0.7s;
			}
		}

		&_active {
			&::before,
			&:after {
				opacity: 0;
				transition: opacity 0.1s ease-in;
			}
			img {
				opacity: 1;
				transition: opacity 0.5s ease-in;
			}
		}

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		&_image {
			width: 100%;
			object-fit: cover;
			filter: brightness(0.6);
		}
	}
}

@keyframes ImageApper {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes ImageDisapper {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.vine_cheese {
	background-color: $color-bg-red;
	padding: calc(88px + 3.62vh) 6.77vw 17.55vh 30px;

	@media (max-width: 1366px) {
		padding: calc(88px + 1.62vh) 6.77vw 17.55vh 30px;
	}

	@media (max-width: 1279px) {
		padding: calc(88px + 3.53vh) 30px 130px 30px;
	}

	@media (max-width: 743px) {
		// padding: calc(68px + 3.75vh) 10px 12.63vh 10px;
		padding: calc(68px + (var(--app-height) * 3.75 / 100)) 10px calc(var(--app-height) * 12.63 / 100) 10px;
	}

	&__nav {
		display: flex;
		flex-grow: 1;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 4.68vh;
		transition: opacity 0.3s ease-in;
		
		@media (max-width: 1366px) {
			margin-bottom: 2.68vh;
		}

		@media (max-width: 1279px) {
			flex-wrap: nowrap;
			flex-direction: column;
			justify-content: flex-start;
		}

		@media (max-width: 743px) {
			// margin-bottom: 32px;
			margin-bottom: calc(var(--app-height) * 4.44 / 100);
		}
	}
	&__contacts {
		flex-direction: row;
		transition: opacity 0.3s ease-in;

		@media (max-width: 1700px) {
			align-items: flex-start;
		}
	}
}

.batya29 {
	background-color: $color-bg-dark;
	justify-content: space-between;
	padding: calc(88px + 3.62vh) 30px 17.55vh 13.8vh;

	@media (max-width: 1366px) {
		padding: calc(88px + 1.62vh) 30px 17.55vh 13.8vh;
	}

	@media (max-width: 1279px) {
		padding: calc(88px + 3.53vh) 30px 130px 30px;
	}

	@media (max-width: 743px) {
		// padding: calc(68px + 3.75vh) 10px 12.63vh 10px;
		padding: calc(68px + (var(--app-height) * 3.75 / 100)) 10px calc(var(--app-height) * 12.63 / 100) 10px;
	}
	&__nav {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		text-align: end;
		margin-bottom: 5.31vh;
		transition: opacity 0.3s ease-in;
		
		@media (max-width: 1366px) {
			margin-bottom: 2.68vh;
		}

		@media (max-width: 1279px) {
			flex-wrap: nowrap;
			flex-direction: column;
			justify-content: flex-start;
		}

		@media (max-width: 743px) {
			// margin-bottom: 32px;
			margin-bottom: calc(var(--app-height) * 4.44 / 100);
		}
	}
	&__contacts {
		flex-direction: row-reverse;
		transition: opacity 0.3s ease-in;
		@media (max-width: 1700px) {
			align-items: flex-end;
		}
	}
	.socials {
		flex-direction: row-reverse;
	}
}

.socials {
	display: flex;
	gap: 30px;
	position: relative;
	z-index: 1;
	@media (max-width: 1279px) {
		margin-bottom: 2.82vh;
	}
	&__item {
		position: relative;
		transition: opacity 0.2s ease-in;
		animation: TextAnimation 0.5s ease forwards;
		&::after {
			content: "";
			position: absolute;
			height: 2px;
			width: 0;
			bottom: -2px;
			left: 0;
			background-color: $color-text-light;
			transition: width 0.3s ease;
		}
		&:hover {
			&::after {
				width: 100%;
			}
		}

		@media (max-width: 1279px) {
			&:after {
				width: 100%;
				height: 1px;
			}
		}
	}
}

.contacts {
	display: flex;
	justify-content: space-between;
	font-size: 1.56vw;
	line-height: 100%;
	position: relative;
	z-index: 1;

	@media (max-width: 1279px) {
		flex-direction: column;
		font-size: 2.68vw;
	}

	@media (max-width: 743px) {
		// font-size: 2.5vh;
		font-size: calc(var(--app-height) * 2.5 / 100);
		margin-top: auto;
	}

	&__link {
		position: relative;
		width: fit-content;
		transition: opacity 0.2s ease-in;
		animation: TextAnimation 0.5s ease forwards;
		&::after {
			content: "";
			position: absolute;
			height: 2px;
			width: 0;
			bottom: -2px;
			left: 0;
			background-color: $color-text-light;
			transition: width 0.3s ease;
		}
		&:hover {
			&::after {
				width: 100%;
			}
		}

		@media (max-width: 1279px) {
			text-decoration: underline;
			text-underline-offset: 3px;

			&:after {
				display: none;
			}
		}
	}
}
