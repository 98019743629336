@import "src/assets/scss/core/variables";

.page {
  background: $color-bg-dark;
  color: $color-text-light;

  &__wrapper {
    // padding-right: 201px;

    & > section {
      padding-right: 201px;

      @media(max-width: 1279px) {
        padding-right: 0;
      }
    }

    @media (max-width: $first-breakpoint) {
      margin: 0;
      padding: 0 32px;
      width: 100%;
    }

    @media (max-width: 743px) {
      margin: 0;
      padding: 0 16px;
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: #fff;
      transition: 0.3s all ease;

      &:hover {
        background: $color-bg-red;
      }
    }
  }

  &__header {
    position: static;
  }
  &__footer {
    color: $color-text-light;
  }
  &__title {
    @media (max-width: $first-breakpoint) {
      font-size: 12.09vw;
      line-height: 120%;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 20px 0;
      text-align: end;
    }
    @media (max-width: $second-breakpoint) {
      font-size: 44px;
      line-height: 44px;
      padding: 25px 0;
    }
  }
  &__next {
    padding: 105px 57px;
    background-color: $color-bg-light;
    color: $color-text-dark;
    position: fixed;
    left: calc(100% + 1px);
    bottom: 0;

    @media (max-width: $first-breakpoint) {
      padding: 32px 32px;
      margin-left: -32px;
      width: calc(100% + 64px);
      align-items: center;
      justify-content: space-between;
      position: initial;
    }

    @media (max-width: 743px) {
      & > div {
        width: 61vw;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          vertical-align: top;
        }
      }
    }

    @media (max-width: $third-breakpoint) {
      padding: 15px 20px;

      & div {
        &:first-child {
          width: 300px;
        }
      }
    }
  }

  &__slider {
    &::before {
      background: $color-bg-light;
    }
    &::after {
      background: $color-bg-light;
    }
  }
  &__menu {
    h1 {
      &::after {
        background: $color-bg-light;
      }
    }
    ul {
      position: relative;
      background-color: $color-bg-light;
      grid-template-rows: repeat(2, 1fr);
      li {
        background-color: $color-bg-dark;
        &:last-child {
          grid-area: 1 / 2 / 3 / 3;
        }
        a {
          &::after {
            background-color: $color-bg-light;
          }
          &::before {
            background-image: url("../../assets/images/icon_pdf_light.svg");
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
