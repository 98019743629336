@import '/src/assets/scss/core/variables';

.textarea {
  height: 63px;
  max-height: 100%;
  &__field {
    width: 100%;
    padding: 15px 5px;
    height: 64px;
    font-size: 30px;
    line-height: 33px;
    font-weight: 500;
    border-bottom: 1px solid currentColor;
    overflow-y: hidden;
    text-transform: uppercase;
    position: relative;
    resize: none;

    &::placeholder {
      text-transform: uppercase;
      color: currentColor;
      transition: opacity 0.2s;
      text-align: center;
    }
    &::placeholder {
      text-transform: uppercase;
      color: currentColor;
      transition: color 0.2s ease-in, opacity 0.2s ease-in;
    }
    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }
    &:hover {
      &:focus {
        &::placeholder {
          opacity: 0;
        }
      }
      &::placeholder {
        opacity: 0.3;
      }
    }
    &_error {
      position: absolute;
      font-size: 16px;
      line-height: 19px;
      text-transform: none;
      margin-top: 3px;
    }
  }
}
