@import '../../../assets/scss/core/variables';

.menu {
  display: flex;
  min-height: 100%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: $color-bg-dark;
    transform: translateY(100%);
    transition: transform 0.7s ease-in;
  }
  @media (max-width: $first-breakpoint) {
    min-height: auto;
    flex-direction: column;
    &::after {
      top: 100%;
      height: 1px;
      width: 100%;
      transform: translateX(100%);
    }
  }
  &__title {
    font-size: 7.87vh;
    line-height: 120%;
    padding: 0px 34px 0px;
    position: relative;

    @media (max-width: 1279px) {
      font-size: 9.94vw;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 32px 0 24px;
    }

    @media(max-width: 743px) {
      font-size: 10.55vw;
    }
  }

  &_active {
    &::after {
      transform: translateY(0%);
      @media (max-width: $first-breakpoint) {
        transform: translateX(0%);
      }
    }
  }
  &__cards {
    display: grid;
    grid-auto-flow: column dense;
    // grid-auto-columns: 720px;
    grid-auto-columns: 37.5vw;
    grid-template-rows: 1fr 1fr;
    gap: 1px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background: $color-bg-dark;
      transform: translateY(100%);
      transition: transform 0.7s ease-in;
      @media (max-width: $first-breakpoint) {
        transform: translateX(100%);
      }
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 1px;
      width: 1px;
      background: $color-bg-dark;
      transition: all 0.7s ease-in;
    }

    &_single {
      grid-template-rows: 1fr;
      grid-auto-flow: row;
    }

    &_active {
      &::after {
        transform: translateY(0%);
        @media (max-width: $first-breakpoint) {
          transform: translateX(0%);
        }
      }
      &::before {
        height: 99.1%;
        width: 99.95%;

        @media (max-width: $first-breakpoint) {
          width: 99.9%;
        }

        @media(max-width: 743px) {
          width: 99%;
        }
      }
    }

    @media (max-width: $first-breakpoint) {
      grid-auto-flow: row;
      flex-grow: 1;
      grid-auto-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      &::after {
        top: 0;
        height: 1px;
        width: 100%;
      }

      &_single {
        grid-template-rows: 1fr;
      }
    }
  }
  &__card {
    font-size: 3.12vw;
    line-height: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-bg-cold;

    @media (max-width: $first-breakpoint) {
      padding: calc(32px + 7vw) 0;
    }
    & a {
      transform: translateY(20px);
      opacity: 0;
      transition: transform 0.5s ease-in, opacity 0.5s ease-in 0.3s;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        height: 3px;
        width: 0%;
        bottom: -2px;
        left: 0;
        transition: width 0.3s ease;
        background-color: $color-bg-dark;
      }
      &::before {
        content: '';
        opacity: 0;
        transition: opacity 0.5s ease-in 1s;
        position: absolute;
        width: 32px;
        height: 32px;
        background: url('../../../assets/images/icon_pdf_dark.svg') center center/contain no-repeat;
        top: -50%;
        right: -10%;
        // @media (max-width: $second-breakpoint) {
        //   background-size: contain;
        //   width: 24px;
        //   height: 24px;
        //   top: -100%;
        //   right: -15%;
        // }
      }

      &:hover {
        &:after {
          width: 100%;
        }
      }

      @media(max-width: 1279px) {
        font-size: 8.06vw;
        line-height: 120%;
        text-decoration: underline 4px;
        text-underline-offset: 8px;

        &::after {
          display: none;
        }

        &::before {
          top: 0;
          right: 0;
          transform: translate(100%, -100%);
        }
      }

      @media(max-width: 743px) {
        font-size: 6.1vw;
        // width: auto;
        text-decoration: underline 1px;
        text-underline-offset: 3px;
        &::before {
          width: 16px;
          height: 16px;
        }
      }
    }
    &_active {
      & a {
        transform: translateY(0px);
        opacity: 1;
        &::before {
          opacity: 1;
        }
      }
    }
  }
}
