@import "../../assets/scss/core/variables";

.iosPopup {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: var(--app-height);
	background-color: #000;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s ease;

	iframe {
		display: block;
		width: 100%;
		height: 100%;
		border: none;
	}

	&_visible {
		opacity: 1;
		visibility: visible;
	}
}
