@import '../../../../assets/scss/core/variables';

.block {
  font-family: $font-stack-secondary;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 0 80px;
  font-size: 3.12vw;
  line-height: 120%;
  font-weight: 400;
  width: 55.1vw;

  @media (max-width: 1279px) {
    min-width: 100%;
    padding: 40px 0;
    width: 100%;
    flex-direction: column-reverse;
  }

  @media(max-width:743px) {
    padding: 24px 0;
  }

  &__container {
    position: relative;
    height: 54vh;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      transition: height 1s ease-in;
      background-color: $color-bg-warm;
    }
    @media (max-width: $first-breakpoint) {
      min-width: auto;
      padding: 40px 0;
      width: 100%;
      &::after {
        width: 100%;
        transition: width 1s ease-in;
        background-color: $color-bg-warm;
      }
    }

    @media(max-width:743px) {
      padding: 24px 0 0;
      height: 70vw;
    }

    &_active {
      &::after {
        height: 0px;
      }
      @media (max-width: $first-breakpoint) {
        &::after {
          width: 0px;
          height: 100%;
        }
      }
    }

    &_image {
      object-fit: cover;
      width: 100%;
      height: 100%;

      @media (max-width: $first-breakpoint) {
        object-fit: fill;
        width: 100%;
        height: auto;
      }

      @media(max-width:743px) {
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__info {
    margin: auto 0 0;
    font-size: 3.12vw;
    line-height: 120%;
    font-weight: 400;
    transform-origin: 0% 50%;
    transform: translate(0, 25%) skew(-15deg, 0) scale(1, 1.2);
    opacity: 0;
    transition: transform 1s ease-in, opacity 0.5s ease-in 0.5s;
    &_active {
      opacity: 1;
      transform: translate(0, 0%) skew(0, 0) scale(1, 1);
    }
    @media (max-width: $first-breakpoint) {
      width: 100%;
      text-align: end;
      font-size: 8.06vw;
      letter-spacing: -0.01em;
    }
  }
}
