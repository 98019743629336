@import 'src/assets/scss/core/variables';

.merch {
  display: flex;
  min-height: 100%;
  @media (max-width: $first-breakpoint) {
    min-height: auto;
    flex-direction: column;
  }
  &__title {
    font-size: 7.87vh;
    line-height: 120%;
    padding: 0px 34px 0px;
    margin-right: 80px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background: $color-bg-light;
      transform: translateY(-100%);
      transition: transform 0.7s ease-in;
      @media (max-width: $first-breakpoint) {
        top: 100%;
        height: 1px;
        width: 100%;
        transform: translateX(100%);
      }
    }
    &_active {
      &::after {
        transform: translateY(0%);
        @media (max-width: $first-breakpoint) {
          transform: translateX(0%);
        }
      }
    }
    @media (max-width: $first-breakpoint) {
      font-size: 10.2vw;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 34px 0 20px;
      margin: 0;
      margin-bottom: 40px;
      &::after {
        top: 100%;
        left: 0;
        height: 1px;
        width: 100%;
        background: $color-bg-light;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background: $color-bg-light;
      }
    }
    @media (max-width: $second-breakpoint) {
      font-size: 10.55vw;
      padding: 24px 0 20px;
    }
  }
  &__description {
    margin-right: 155px;
    display: flex;
    flex-direction: column;
    width: 25vw;
    flex-shrink: 0;
    @media (max-width: $first-breakpoint) {
      max-width: 100%;
      min-width: 100%;
      margin: 0;
      margin-bottom: 40px;
    }
  }
  &__shop {
    display: flex;
    align-items: center;
    @media (max-width: $first-breakpoint) {
      flex-direction: column;
    }
  }

  &__shopBlock {
    height: 100%;

    @media(max-width:743px) {
      width: 100%;
    }
  }

  &__outro {
    &_active {
      opacity: 0;
      transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
      animation: TextAnimation 1s ease forwards;
    }
    font-family: $font-stack-secondary;
    font-size: 3.855vw;
    line-height: 120%;
    font-weight: 400;
    margin: 0 160px 0 100px;
    width: 39.06vw;
    flex-shrink: 0;
    text-transform: none;
    align-self: center;
    @media (max-width: $first-breakpoint) {
      min-width: 100%;
      margin: 0;
      font-size: 8.06vw;
      margin: 40px 0;
      text-align: end;
    }
  }
}

.description {
  &_active {
    .description {
      &__title {
        opacity: 0;
        transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
        animation: TextAnimation 1s 0.2s ease forwards;
        @media (max-width: 1470px) {
          animation: TextAnimation 1s ease forwards;
        }
      }
      &__info {
        opacity: 0;
        transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
        animation: TextAnimation 1s 0.2s ease forwards;
        @media (max-width: 1470px) {
          animation: TextAnimation 1s ease forwards;
        }
      }
    }
  }
  &__title {
    font-family: $font-stack-secondary;
    font-weight: 400;
    text-transform: lowercase;
    font-size: 3.12vw;
    line-height: 120%;
    margin-bottom: 64px;

    @media (max-width: $first-breakpoint) {
      width: 70%;
      margin-left: auto;
      text-align: end;
      margin-bottom: 24px;
      font-size: 8.06vw;
    }
  }
  &__info {
    font-size: 1.56vw;
    line-height: 110%;

    @media (max-width: $first-breakpoint) {
      width: 70%;
      margin-right: auto;
      font-size: 4.03vw;
    }
    @media (max-width: $second-breakpoint) {
      width: 100%;
      margin-right: 0;
      font-size: 7.2vw;
    }
  }
}

.content {
  display: flex;
  margin-right: 64px;
  height: 100%;
  @media (max-width: $first-breakpoint) {
    flex-direction: column;
    margin: 0;
    margin-bottom: 10px;
  }
  &:last-child {
    margin: 0;
  }
  &_active {
    .content__title {
      transform: rotate(-180deg) translateY(0%);
      opacity: 1;
    }
  }
  &__title {
    opacity: 0;
    display: flex;
    padding: 0 0 0 64px;
    font-size: 1.56vw;
    line-height: 110%;
    font-weight: 400;
    writing-mode: vertical-rl;
    transform: rotate(-180deg) translateY(-100%);
    transition: transform 0.7s ease, opacity 0.7s ease 0.3s;
    @media (max-width: $first-breakpoint) {
      padding: 8px 0;
      font-size: 2.7vw;
    }
    @media (max-width: $second-breakpoint) {
      font-size: 18px;
      line-height: 18px;
    }
  }
  &__images {
    display: flex;

    @media (max-width: $first-breakpoint) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 61.43vw;
      gap: 8px;
    }

    @media (max-width: 743px) {
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: 123.9vw;
      gap: 8px;
    }
  }
  &__container {
    margin-right: 65px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 105%;
      transform-origin: top;
      transition: height 1s ease-in;
      background-color: $color-bg-dark;
    }
    @media (max-width: $first-breakpoint) {
      &::after {
        width: 100%;
        transition: width 1s ease-in;
        background-color: $color-bg-dark;
      }
    }
    &_active {
      &::after {
        height: 0px;
      }
      @media (max-width: $first-breakpoint) {
        &::after {
          width: 0px;
          height: 101%;
        }
      }
    }
    @media (max-width: $first-breakpoint) {
      margin-right: 0;
    }
    @media (max-width: $third-breakpoint) {
      width: 100%;
    }
    &_image {
      min-width: 29.16vw;
      object-fit: cover;
      @media (max-width: $first-breakpoint) {
        min-width: auto;
      }
      @media (max-width: $third-breakpoint) {
        width: 100%;
      }
    }
  }
}

.modal {
  &_enter_active {
    animation: ModalApper 0.5s ease-in;
    & > div {
      opacity: 0;
      animation: ModalOpacity 0.7s ease-in 0.5s forwards;
    }
  }
  &_exit_active {
    animation: ModalDisapper 0.5s ease-in forwards;
  }
}

@keyframes ModalApper {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes ModalOpacity {
  to {
    opacity: 1;
  }
}

@keyframes ModalDisapper {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes TextAnimation {
  from {
    opacity: 0;
    transform: translate(0, 130%) skew(-10deg, 0) scale(1, 1.5);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
