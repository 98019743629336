@import '../../assets/scss/core/variables';

.page {
  background: $color-bg-dark;
  color: $color-text-light;
  padding: 0;
  
  &__header {
    position: absolute;
  }
}
