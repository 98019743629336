@import '../../assets/scss/core/variables';

.modal {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--app-height);
  width: 100vw;
  display: flex;
  overflow-y: overlay;
  overflow-x: hidden;
  z-index: 1000;
  background: $color-bg-warm;
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    display: none;

    @media(max-width:1279px) {
      display: block;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background: #000;
    transition: 0.3s all ease;

    &:hover {
      background: $color-bg-red;
    }
  }
  @media (max-width: 1300px) {
    flex-direction: column;
  }
  &__container {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 40vw;

    @media(max-width: 1279px) {
      position: static;
      width: 100%;
    }

    &_image {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 1279px) {
        max-width: 100%;
        width: 100%;
        height: 46.2vh;
        object-fit: cover;
      }

      @media(max-width:743px) {
        height: 70.28vh;
      }
    }
  }
  &__close {
    position: absolute;
    display: flex;
    top: 20px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;

    @media(max-width:743px) {
      top: 16px;
      right: 16px;
      width: 48px;
      height: 48px;

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
        vertical-align: top;
      }
    }
  }
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 60vw;
  height: var(--app-height);
  padding: 10.64vh 0 8vh 4.17vw;

  @media(max-width: 1279px) {
    position: static;
    width: 100%;
    padding: 24px 32px 32px;
    height: auto;
  }

  @media(max-width: 743px) {
    padding: 32px 16px 64px;
  }

  &__scroll {
    overflow: hidden auto;
    height: 100%;
    padding-right: 35px;
    
    &::-webkit-scrollbar {
      width: 8px;
      height: 10px;

      @media(max-width:1279px) {
        display: none;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: #000;
      transition: 0.3s all ease;

      &:hover {
        background: $color-bg-red;
      }
    }

    @media(max-width:1279px) {
      padding: 0;
      height: initial;
      overflow: initial;
    }
  }

  &__title {
    text-transform: lowercase;
    font-family: $font-stack-secondary;
    font-size: 5.729vw;
    line-height: 100%;
    margin-bottom: 3.4vh;
    font-weight: 400;

    @media(max-width: 1279px) {
      font-size: 12.1vw;
      margin-bottom: 2.12vh;
    }

    @media(max-width:743px) {
      margin-bottom: 32px;
    }
  }

  &__quote {
    font-size: 1.56vw;
    line-height: 110%;
    margin-bottom: 5.1vh;

    @media(max-width: 1279px) {
      font-size: 4.03vw;
      margin-bottom: 2.82vh;
    }

    @media(max-width: 1279px) {
      font-size: 7.22vw;
      margin-bottom: 40px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    text-transform: none;
    margin-bottom: 40px;
    &_text {
      margin-bottom: 40px;
      font-variation-settings: normal;
      font-stretch: normal;
      &:last-child {
        margin: 0;
      }
    }
  }
  &__info {
    text-transform: none;
    font-size: 1.04vw;
    line-height: 120%;
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
    font-variation-settings: normal;
    font-stretch: normal;

    @media(max-width: 1279px) {
      font-size: 2.68vw;
    }

    @media(max-width: 1279px) {
      font-size: 3.9vw;
    }
  }
  &__link {
    align-self: flex-end;
    position: fixed;
    bottom: 35px;
    right: 35px;
    margin-top: auto;
    font-size: 1.56vw;
    line-height: 110%;
    width: fit-content;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 0;
      bottom: -1px;
      left: 0;
      background-color: currentColor;
      transition: 0.3s width ease;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }

    @media(max-width: 1279px) {
      position: relative;
      margin: auto auto 0;
      right: auto;
      bottom: auto;
      font-size: 4.03vw;
      align-self: center;
      font-weight: 600;

      &:after {
        width: 100%;
      }
    }

    @media(max-width: 743px) {
      font-size: 7.22vw;
    }
  }
}

.feature {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #000;
  
  &__container {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 60vw;
    height: auto;
    max-height: var(--app-height);
    padding: 0 32px 0 80px;
    z-index: -1;
    pointer-events: none;
    &_image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: top;
    }

    @media(max-width:1279px) {
      padding: 0 32px;
      position: fixed;
      width: 100%;
      transform: translate(0);
      top: 50vh;
    }

    @media(max-width:743px) {
      padding: 0 16px;
      top: auto;
      bottom: 0;
    }
  }
  &__block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4.2vw;
    text-transform: lowercase;

    @media(max-width:743px) {
      display: block;
    }

    &_item {
      display: flex;
      flex-direction: column;

      &_title {
        font-family: $font-stack-secondary;
        font-weight: 400;
        font-size: 2.08vw;
        line-height: 120%;
        margin-bottom: 16px;
      }

      &_info {
        font-weight: 400;
        font-size: 1.04vw;
        line-height: 120%;
        text-transform: none;
        font-variation-settings: normal;
        font-stretch: normal;
      }

      @media(max-width:1279px) {
        &_title {
          font-size: 5.37vw;
        }

        &_info {
          font-size: 2.68vw;
        }
      }

      @media(max-width:743px) {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }

        &_title {
          font-size: 7.22vw;
        }

        &_info {
          font-size: 5.55vw;
        }
      }
    }
  }
}

.modal {
  &_enter_active {
    animation: ModalApper 0.5s ease-in;
    & > div {
      opacity: 0;
      animation: ModalOpacity 0.7s ease-in 0.5s forwards;
    }
    & > button {
      opacity: 0;
      animation: ModalOpacity 0.7s ease-in 0.5s forwards;
    }
  }
  &_exit_active {
    animation: ModalDisapper 0.5s ease-in forwards;
  }
}

@keyframes ModalApper {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes ModalOpacity {
  to {
    opacity: 1;
  }
}

@keyframes ModalDisapper {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
