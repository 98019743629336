@import 'src/assets/scss/core/variables';

.about {
  display: flex;
  min-height: 100%;
  @media (max-width: $first-breakpoint) {
    min-height: auto;
    flex-direction: column;
  }
  &__title {
    font-size: 7.87vh;
    line-height: 120%;
    padding: 0px 34px 0px;
    position: relative;

    @media (max-width: 1279px) {
      font-size: 9.94vw;
      line-height: 120%;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 32px 0 24px;
      border-bottom: 1px solid #000;
      &::after {
        content: none;
      }
    }

    @media(max-width:743px) {
      font-size: 10.55vw;
    }
  }
  &__block {
    display: flex;
    padding: 0 80px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background: $color-bg-dark;
      transform: translateY(100%);
      transition: transform 0.7s ease-in;
    }
    &_active {
      &::after {
        transform: translateY(0%);
      }
    }

    @media (max-width: 1279px) {
      flex-direction: column;
      padding: 64px 0 32px;
      &::after {
        content: none;
      }
    }

    @media(max-width: 743px) {
      padding: 24px 0 32px;
    }
  }
}

.section {
  display: flex;
  &:first-child {
    flex-direction: row;
    margin-right: 80px;
    @media (max-width: $first-breakpoint) {
      flex-direction: column;
      margin: 0px;
      margin-bottom: 64px;
    }
    @media (max-width: 743px) {
      margin-bottom: 24px;
    }
    .section {
      &__info {
        display: flex;
        font-family: $font-stack-secondary;
        font-weight: 400;
        text-transform: lowercase;
        font-size: 3.12vw;
        line-height: 120%;
        width: 29.17vw;
        margin-right: 80px;
        transform-origin: 50% 50%;
        transform: translate(0, 20%) skew(-15deg, 0) scale(1, 1.15);
        opacity: 0;
        transition: transform 1s ease-in, opacity 0.5s ease-in 0.5s;
        flex-shrink: 0;
        &_active {
          opacity: 1;
          transform: translate(0, 0%) skew(0, 0) scale(1, 1);
        }
        @media (max-width: 1279px) {
          min-width: auto;
          width: 100%;
          margin-right: 0;
          margin-bottom: 64px;
          text-align: right;
          font-size: 8.06vw;
        }

        @media (max-width: 743px) {
          margin-bottom: 24px;
        }
      }
      &__container {
        display: flex;
        height: auto;
        position: relative;
        min-width: 33.334vw;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          transition: height 1s ease-in;
          background-color: $color-bg-cold;
        }
        @media (max-width: $first-breakpoint) {
          &::after {
            width: 100%;
            transition: width 1s ease-in;
            background-color: $color-bg-cold;
          }
        }
        &_active {
          &::after {
            height: 0px;
          }
          @media (max-width: $first-breakpoint) {
            &::after {
              width: 0px;
              height: 100%;
            }
          }
        }
        @media (max-width: $first-breakpoint) {
          min-width: auto;
          max-height: 550px;
        }
        @media (max-width: 750px) {
          max-height: 450px;
        }
        @media (max-height: 900px) and (min-width: $first-breakpoint) {
          min-width: 450px;
        }
        &_image {
          object-fit: cover;
          aspect-ratio: 3/4;
          width: 100%;
          height: 100%;
          @media (max-width: $first-breakpoint) {
            object-fit: cover;
          }
        }
      }
    }
  }
  &:last-child {
    flex-direction: column;
    align-items: center;
    width: 910px;
    @media (max-width: $first-breakpoint) {
      width: 100%;
    }
    @media (max-height: 900px) and (min-width: $first-breakpoint) {
      width: 700px;
    }
    .section {
      &__info {
        font-size: 1.56vw;
        line-height: 120%;
        margin: auto 0;
        transform-origin: 0% 50%;
        transform: translate(0, 25%) skew(-15deg, 0) scale(1, 1.2);
        opacity: 0;
        transition: transform 1s ease-in, opacity 0.5s ease-in 0.5s;

        &_active {
          opacity: 1;
          transform: translate(0, 0%) skew(0, 0) scale(1, 1);
        }

        @media (max-width: $first-breakpoint) {
          width: 68.55vw;
          margin: 0;
          margin-right: auto;
          text-align: left;
          font-size: 4.03vw;
        }

        @media(max-width:743px) {
          width: 100%;
          font-size: 7.2vw;
          letter-spacing: -0.03em;
        }
      }
      &__container {
        display: flex;
        min-width: 910px;
        height: auto;
        margin-top: 20px;
        position: relative;
        @media (max-width: $first-breakpoint) {
          min-width: 100%;
          margin-top: 24px;
        }
        @media (max-height: 900px) and (min-width: $first-breakpoint) {
          min-width: 700px;
        }
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          transition: height 1s ease-in;
          background-color: $color-bg-cold;
        }
        @media (max-width: $first-breakpoint) {
          &::after {
            width: 100%;
            transition: width 1s ease-in;
            background-color: $color-bg-cold;
          }
        }
        &_active {
          &::after {
            height: 0px;
          }
          @media (max-width: $first-breakpoint) {
            &::after {
              width: 0px;
              height: 101%;
            }
          }
        }
        &_image {
          aspect-ratio: 16/10;
          object-fit: fill;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.link {
  font-size: 1.56vw;
  line-height: 110%;
  font-weight: 500;
  color: $color-text-red;
  border-bottom: 2px solid currentColor;
  width: fit-content;
  margin: 10px auto 100px;

  @media(max-width:1279px) {
    margin: 0 auto 100px;
    font-size: 4.03vw;
  }

  @media(max-width: 743px) {
    margin: 0 auto 84px;
    font-size: 7.2vw;
  }
}

.modal {
  &_enter_active {
    animation: ModalApper 0.5s ease-in;
    & > div {
      opacity: 0;
      animation: ModalOpacity 0.7s ease-in 0.5s forwards;
    }
  }
  &_exit_active {
    animation: ModalDisapper 0.5s ease-in forwards;
  }
}

@keyframes ModalApper {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes ModalOpacity {
  to {
    opacity: 1;
  }
}

@keyframes ModalDisapper {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
