@import '../../assets/scss/core/variables';

.section {
    z-index: 1;
    // Перемещение по секциям
    &_enter {
        transform: translateX(100vw);
    }

    &_enter_active {
        transform: translateX(0);
        transition: transform 1.5s;

        h1 {
            animation: EnterTitle 1.5s forwards;
        }
    }

    &_exit {
        transform: translateX(0);
    }

    &_exit_active {
        transform: translateX(-100%);
        transition: transform 1.5s;

        h4 {
            animation: ExitTitle 1.5s forwards;
        }
    }

    // Перемещение через меню
    &_enterMenu {
        opacity: 0;
    }

    &_enterMenu_active {
        opacity: 1;
        transition: opacity 1.5s;
    }

    &_exitMenu {
        opacity: 1;
    }

    &_exitMenu_active {
        opacity: 0;
        transition: opacity 1.5s;
    }

    // Перемещение через логотипы
    &_enterVine {
        opacity: 0;
    }

    &_enterVine_active {
        opacity: 1;
        transition: 0.1s 0.6s ease-in;
    }

    &_exitVine {
        transform: translateY(0);
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $color-bg-red;
          z-index: 9;
          transform: translateY(var(--app-height));
        }
    }
    
    &_exitVine_active {
        transform: translateY(-100vh);
        transition: transform 0.7s 0.7s ease-in;
        &:after {
          transform: translateY(0);
          transition: 0.7s ease-in;
        }
    }

    &_enterBatya {
        opacity: 0;
    }

    &_enterBatya_active {
        opacity: 1;
        transition: 0.1s 0.6s ease-in;
    }
    
    &_exitBatya {
        transform: translateY(0);
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          z-index: 20;
          transform: translateY(var(--app-height));
        }
    }
    
    &_exitBatya_active {
        transform: translateY(-100vh);
        transition: transform 0.7s 0.7s ease-in;
        &:after {
          transform: translateY(0);
          transition: 0.7s ease-in;
        }
    }

    @media (max-width: 1279px) {
        &_enter {
            transform: translateY(var(--app-height));
        }
    
        &_enter_active {
            transform: translateY(0);
            transition: transform 1.5s;
    
            h1 {
                animation: EnterTitleXs 1.5s forwards;
            }
        }
    
        &_exit {
            transform: translateY(0);
        }
    
        &_exit_active {
            transform: translateY(-100%);
            transition: transform 1.5s;
    
            h4 {
                animation: ExitTitleXs 1.5s forwards;
            }
        }
    }
}

@keyframes EnterTitle {
    0% {
        transform: translateX(calc(-100% - 20px)) rotate(-180deg);
    }
    50% {
        transform: translateX(0) rotate(-180deg);
    }
    100% {
        transform: translateX(0) rotate(-180deg);
    }
}

@keyframes ExitTitle {
    0% {
        transform: translateX(0) rotate(-180deg);
    }
    50% {
        transform: translateX(calc(100% + 20px)) rotate(-180deg);
    }
    100% {
        transform: translateX(calc(100% + 20px)) rotate(-180deg);
        opacity: 0;
    }
}

@keyframes EnterTitleXs {
    0% {
        transform: translateY(calc(-100% - 20px));
    }
    50% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes ExitTitleXs {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(calc(100% + 20px));
    }
    100% {
        transform: translateY(calc(100% + 20px));
        opacity: 0;
    }
}