/*variables*/
@import 'src/assets/scss/core/variables';
/*fonts*/
@import 'src/assets/scss/core/fonts';
/*extends*/
@import 'src/assets/scss/core/extends';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  display: block;
}

ul,
ol {
  list-style: none;
}

button,
select,
input,
textarea {
  font: inherit;
  color: inherit;
  border: none;
  background: transparent;
  outline: none;
}

body {
  font-family: $font-stack-primary;
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
  min-width: 300px;
  color: $color-text-dark;
  text-transform: uppercase;
  max-width: 100%;
  overflow: hidden;
  height: var(--app-height);
  font-variation-settings: 'GRAD' -200, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 34;
  font-stretch: 40%;
}
