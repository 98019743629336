@import '../../../../assets/scss/core/variables';

.page {
    background-color: $color-bg-red;

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;

        @media(max-width:743px) {
            width: max-content;
        }
    }

    &__title {
        font-family: $font-stack-secondary;
        font-size: 3.12vw;
        line-height: 120%;
        font-weight: 400;
        letter-spacing: -0.01em;
        margin-bottom: 8.51vh;

        @media(max-width: 1279px) {
            font-size: 8.06vw;
            margin-bottom: 7.06vh;
        }

        @media(max-width: 743px) {
            font-size: 16.12vw;
        }
    }

    &__btnWrap {
        display: flex;
        justify-content: space-between;
    }

    &__btn {
        font-size: 1.56vw;
        line-height: 120%;
        text-transform: uppercase;
        text-decoration: underline 3px;
        text-underline-offset: 5px;
        cursor: pointer;

        @media(max-width: 1279px) {
            font-size: 4.03vw;
        }
        @media(max-width: 743px) {
            font-size: 8.06vw;
        }
    }
}