@import '/src/assets/scss/core/variables';

.page {
  background: $color-bg-dark;
  flex-direction: row;
  height: 100vh !important;

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 152px;
    height: 152px;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: top;
    }
  }

  &__img {
    animation: StrokeMove 1s linear infinite;
  }
}

@keyframes StrokeMove {
  0% {
    stroke-dasharray: 420;
    stroke-dashoffset: -420;
  }
  50% {
    stroke-dasharray: 420;
    stroke-dashoffset: -840;
  }
  100% {
    stroke-dasharray: 420;
    stroke-dashoffset: -1260;
  }
}
