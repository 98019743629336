@import '/src/assets/scss/core/variables';

.socials {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 20px;
    place-items: center;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
  }
  &__input {
    @media (max-width: 768px) {
      margin-top: auto;
      grid-area: 1 / 2 / 3 / 3;
    }
    @media (max-width: 700px) {
      grid-area: 3 / 1 / 4 / 2;
    }
    @media (max-width: 678px) {
      font-size: 26px;
      line-height: 29px;
      & input {
        &::placeholder {
          font-size: 26px;
          line-height: 29px;
        }
      }
    }
  }
  &__block {
    font-size: 30px;
    line-height: 33px;
    margin-bottom: 40px;
    @media (max-width: 768px) {
      margin: 0;
      margin-top: auto;
      grid-area: 2 / 1 / 3 / 2;
    }
    @media (max-width: 700px) {
      grid-area: 2 / 1 / 3 / 2;
      margin-bottom: 52px;
    }
    @media (max-width: 678px) {
      font-size: 26px;
      line-height: 28px;
    }
    &_light {
      .socials__block_item {
        &_active {
          color: $color-text-light;
        }
      }
    }
    &_dark {
      .socials__block_item {
        &_active {
          color: $color-text-red;
        }
      }
    }
    &_item {
      margin-right: 30px;
      cursor: pointer;
      position: relative;

      &_active {
        color: $color-text-light;
        &::after {
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          bottom: 0;
          left: 0;
          background-color: currentColor;
        }
      }
      &_disabled {
        opacity: 0.4;
      }
      &:last-child {
        margin: 0;
      }
      @media (max-width: 678px) {
        font-size: 26px;
        line-height: 28px;
      }
    }
  }
  &__text {
    margin-bottom: 25px;
    @media (max-width: 768px) {
      grid-area: 1 / 1 / 2 / 2;
    }
    @media (max-width: 700px) {
      grid-area: 1 / 1 / 2 / 2;
      margin-bottom: 32px;
    }
    @media (max-width: 678px) {
      font-size: 18px;
      line-height: 18px;
    }
  }
}
