@import "../../assets/scss/core/variables";

.container {
	display: flex;
	flex-direction: column;
}

.header {
	position: absolute;

	&_hide {
		opacity: 0;
		visibility: hidden;
	}
}

.main {
	$p: &;
	height: 100%;

	@media (max-width: 743px) {
		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 4px;
			background-color: $color-bg-red;
			z-index: 20;
			transition: 0.5s ease all;
		}

		&_batya {
			&:before {
				background-color: #000;
			}
		}
	}

	&__section {
		height: 100%;
	}

	&__sliderWrap {
		position: absolute;
		top: 0;
		height: 100%;
		overflow: hidden;
		transition: 0.5s all ease;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #000;
			opacity: 0.6;
			transition: 0.3s all ease;
			z-index: 10;
			pointer-events: none;

			@media (max-width: 1279px) {
				opacity: 0.6;
			}
		}

		&_cafe {
			left: 0;
			width: 70%;

			#{$p} {
				&__footer {
					left: 25px;
				}
			}

			@media (max-width: 1279px) {
				background-color: $color-bg-red;
				width: 50%;

				&:before {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 4px;
					background-color: $color-bg-red;
					z-index: 20;
				}
			}

			@media (max-width: 743px) {
				width: 80px;

				&::before {
					display: none;
				}

				#{$p} {
					&__footer {
						left: 10px;
					}
				}
			}
		}

		&_batya {
			right: 0;
			width: 30%;

			#{$p} {
				&__footer {
					right: 25px;
				}
			}

			@media (max-width: 1279px) {
				background-color: #000;
				width: 50%;

				&:before {
					content: "";
					position: absolute;
					bottom: 0;
					right: 0;
					width: 100%;
					height: 4px;
					background-color: #000;
					z-index: 20;
				}
			}

			@media (max-width: 743px) {
				width: 80px;

				&::before {
					display: none;
				}

				#{$p} {
					&__footer {
						right: 10px;
					}
				}
			}
		}

		&_hover {
			&:after {
				opacity: 0;

				@media (max-width: 1279px) {
					opacity: 0.6;
				}
			}

			@media (max-width: 743px) {
				width: calc(100% - 80px);
			}

			@media (max-width: 743px) {
				&:after {
					background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 49.08%, #000000 74.87%);
				}
			}
		}

		&_full {
			width: 100vw;

			@media (max-width: 1279px) {
				&:after {
					opacity: 0;
				}

				& > .slick-slide {
					margin-bottom: 8px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		&_hide {
			width: 0;
		}
	}

	&__video {
		position: absolute;
		top: calc(88px + 5.53vh);
		left: 0;
		z-index: 20;
		transform: translateX(-150%);
		transition: transform 0.5s ease;

		@media (max-width: 1279px) {
			transform: translateX(0);
			top: calc(88px + 3vh);
		}

		@media (max-width: 743px) {
			transform: translateX(-150%);
			top: calc(68px + 3.34vh);
		}

		&:not(&_batya) {
			max-width: calc(70% - 20px);

			@media (max-width: 1279px) {
				max-width: calc(50% - 20px);
			}

			@media (max-width: 743px) {
				max-width: calc(100% - 90px);
			}
		}

		&_batya {
			max-width: calc(30% - 20px);
			left: auto;
			right: 0;
			transform: translateX(150%);

			@media (max-width: 1279px) {
				transform: translateX(0);
				max-width: calc(50% - 20px);
			}

			@media (max-width: 743px) {
				transform: translateX(150%);
				max-width: calc(100% - 100px);
			}
		}

		&_show {
			transform: translateX(0);
		}

		&_hide {
			@media (max-width: 1279px) {
				transform: translateX(-150%);
			}
		}

		&_hideBatya {
			@media (max-width: 1279px) {
				transform: translateX(150%);
			}
		}
	}

	&__slider {
		width: 100vw;
		height: 100%;
	}

	&__footer {
		position: absolute;
		bottom: 1.98vw;
		z-index: 20;
		color: #fff;
		width: 100%;

		@media (max-width: 743px) {
			bottom: 2vh;
			width: auto;
		}
	}

	&__static {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__hoverArea {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		display: block;
		height: calc(100% - 280px);
		cursor: pointer;
		z-index: 10;

		&_hide {
			display: none;
		}

		@media (max-width: 1279px) {
			height: var(--app-height);
		}
	}
}

.footer {
	opacity: 0.6;
	transition: 0.3s all ease;

	&_hide {
		opacity: 0;
		visibility: hidden;
		transition: 0.5s all ease;
	}

	&_hover {
		opacity: 1;
	}

	&__logo {
		margin-bottom: 46px;
		height: 104px;

		svg {
			width: auto;
			height: 100%;
		}

		&_batya {
			text-align: right;
		}
	}

	&__nav {
		margin-bottom: 20px;
	}

	&__socials {
		margin-top: 16px;
	}

	&__dividerCafe {
		position: relative;
		display: block;
		height: 1px;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: #fff;
			opacity: 0;
			transition: 0.3s all ease;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 1px;
			background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 65.25%, #ffffff 100%);
			transition: 0.3s all ease;
		}

		&_hover {
			&:before {
				opacity: 1;
			}
			&:after {
				opacity: 0;
			}
		}
	}

	&__dividerBatya {
		position: relative;
		display: block;
		height: 1px;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: #fff;
			opacity: 0;
			transition: 0.3s all ease;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 25px;
			width: 100vw;
			height: 1px;
			background: linear-gradient(90deg, #ffffff 0%, #ffffff 65.25%, rgba(255, 255, 255, 0) 100%);
			transition: 0.3s all ease;
		}

		&_hover {
			&:before {
				opacity: 1;
			}
			&:after {
				opacity: 0;
			}
		}
	}
}

.nav {
	$p: &;

	&_batya {
		#{$p} {
			&__list {
				justify-content: flex-end;
			}
		}
	}
	&__list {
		display: flex;
	}

	&__item {
		margin-right: 1.67vw;

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {
		// font-weight: 600;
		font-size: 1.57vw;
		line-height: 110%;
		text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	}
}

.socials {
	$p: &;

	&_batya {
		#{$p} {
			&__list {
				justify-content: flex-end;
			}
		}
	}

	&__list {
		display: flex;
	}

	&__item {
		margin-right: 1.67vw;

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {
		// font-weight: 600;
		font-size: 1.04vw;
		line-height: 110%;
		text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	}
}

.slideInfo {
	padding: 24px 48px 37px;
	width: 504px;
	background-color: $color-bg-red;
	transform: translateY(100%);
	// transform: translateY(calc(100% - 96px));
	transition: 0.3s all ease;

	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 35px;
	}

	&__title {
		font-family: $font-stack-secondary;
		color: #fff;
		font-weight: 400;
		font-size: 40px;
		line-height: 48px;
	}

	&__descr {
		p {
			font-weight: 400;
			font-size: 20px;
			line-height: 120%;
			color: #fff;
			text-transform: none;
		}
	}
}
