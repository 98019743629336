@import '../../assets/scss/core/variables';

.page {
  background: $color-bg-cold;
  &__header {
    position: static;
  }
  &__footer {
    color: $color-text-dark;
  }
  &__title {
    @media (max-width: 1279px) {
      font-size: 12.09vw;
      line-height: 120%;
      writing-mode: horizontal-tb;
      transform: none;
      min-height: fit-content;
      padding: 40px 0;
    }

    @media(max-width: 743px) {
      padding: 32px 0 24px;
    }
  }
}
