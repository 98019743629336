@import '../../assets/scss/core/variables';

.content {
  display: flex;
  flex: 1 1 auto;
  width: 100vw;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    display: none;

    @media(max-width: 1279px) {
      display: block;
    }
  }

	&::-webkit-scrollbar-track {
		background-color: rgba(0, 0, 0, 0);
	}

	&::-webkit-scrollbar-thumb {
		background: #000;
		transition: 0.3s all ease;

		&:hover {
			background: $color-bg-red;
		}
	}

  @media (max-width: 1279px) {
    overflow-x: hidden;
    overflow-y: overlay;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 743px) {
    width: 100%;
  }

  &__section {
    display: flex;
    flex-wrap: nowrap;
    max-width: fit-content;

    @media (max-width: 1279px) {
      flex-direction: column;
      min-width: 100%;
      width: 100%;
    }
  }
}
